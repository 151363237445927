import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import InputField from '../../InputField';
import PasswordField from '../../PasswordField';
import PhoneField from '../../PhoneField';
import Button from '../../Button';
import CheckboxField from '../../CheckboxField';
import AppLink from '../../AppLink';
import PhoneFieldMultiCountry from '../../PhoneFieldMultiCountry';

// Router
import { getRoute } from '../../../entry/routes';

// Icons
import OpenIcon from '../../../icons/toggle2.svg';

// State
import { signUp } from '../../../state/modules/user/actions';
import userApi from '../../../state/modules/user/api';

// Utils
import { isEmail, isPhoneValid, required, strongPassword } from '../../../utils/validators';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';

// Config
import config from '../../../config';

// Styles
import styles from './SignUpForm.styl';

const cx = classNames.bind(styles);

export default function SignUpForm(props) {
  const { className, successCallback } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [isSigningUp, setIsSigninUp] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isCheckbox1Enabled, setIsCheckbox1Enabled] = useState(false);
  const [isCheckbox3Enabled, setIsCheckbox3Enabled] = useState(false);
  const [isCheckbox4Enabled, setIsCheckbox4Enabled] = useState(false);
  const [isCheckbox5Enabled, setIsCheckbox5Enabled] = useState(false);

  // в Польше кнопка недоступна, пока не прожаты первый и последний чекбоксы
  const isButtonDisabled =
    config.legal === 'pl' || config.legal === 'de'
      ? isFirstStep || !isCheckbox1Enabled || !isCheckbox5Enabled
      : isFirstStep;

  const {
    register,
    handleSubmit,
    errors,
    control,
    setError,
    trigger,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      username: '',
      phone: '',
      email: '',
      password: '',
      password_repeat: '',
      isPhoneValid: false,
      country_code: false,
    },
  });

  // подтверждаем первый шаг
  const onFirstStepSubmit = async () => {
    setIsSigninUp(true);
    const result = await trigger(['username', 'phone', 'email']);

    if (!result) {
      return setIsSigninUp(false);
    }

    let hasError = false;
    const { phone, email } = getValues(['phone', 'email']);

    await userApi
      .hasPhone(phone)
      .then(({ status }) => {
        if (status) {
          hasError = true;
          setError('phone', {
            type: 'manual',
            message: intl.formatMessage({ id: 'signUp.existPhone' }),
          });
        }
      })
      .catch(({ message }) => {
        hasError = true;
        setError('phone', {
          type: 'manual',
          message,
        });
      });

    await userApi
      .hasMail(email)
      .then(({ status }) => {
        if (status) {
          hasError = true;
          setError('email', {
            type: 'manual',
            message: intl.formatMessage({ id: 'signUp.existEmail' }),
          });
        }
      })
      .catch(({ message }) => {
        hasError = true;
        setError('email', {
          type: 'manual',
          message,
        });
      });

    if (!hasError) {
      setIsFirstStep(false);
    }

    setIsSigninUp(false);
  };

  // подтверждаем второй шаг
  const onSubmit = handleSubmit(async formValues => {
    const onSuccessCallback = () => {
      successCallback?.();
      setGlobalMessage(intl.formatMessage({ id: 'signUp.successMessage.text' }), {
        type: 'success',
      });
    };

    setIsSigninUp(true);
    await dispatch(
      signUp(
        {
          ...formValues,
          sms_state:
            config.legal === 'pl' || config.legal === 'de' ? isCheckbox3Enabled : undefined,
          subscription_state:
            config.legal === 'pl' || config.legal === 'de' ? isCheckbox4Enabled : undefined,
        },
        onSuccessCallback,
      ),
    );
    setIsSigninUp(false);
  });

  const [isExpanded, setIsExpanded] = useState(true);

  const acceptAllToggleHandler = useCallback(() => {
    if (isCheckbox1Enabled && isCheckbox3Enabled && isCheckbox4Enabled && isCheckbox5Enabled) {
      setIsCheckbox1Enabled(false);
      setIsCheckbox3Enabled(false);
      setIsCheckbox4Enabled(false);
      setIsCheckbox5Enabled(false);
    } else {
      setIsCheckbox1Enabled(true);
      setIsCheckbox3Enabled(true);
      setIsCheckbox4Enabled(true);
      setIsCheckbox5Enabled(true);
    }
  }, [
    isCheckbox1Enabled,
    isCheckbox3Enabled,
    isCheckbox4Enabled,
    isCheckbox5Enabled,
    setIsCheckbox1Enabled,
    setIsCheckbox3Enabled,
    setIsCheckbox4Enabled,
    setIsCheckbox5Enabled,
  ]);

  const isAllSelected =
    isCheckbox1Enabled && isCheckbox3Enabled && isCheckbox4Enabled && isCheckbox5Enabled;

  return (
    <form className={cx('SignUpForm', className)} onSubmit={onSubmit}>
      <div className={cx('SignUpForm__step', { SignUpForm__step_visible: isFirstStep })}>
        <div className={cx('SignUpForm__field')}>
          <InputField
            name="username"
            label={intl.formatMessage({ id: 'field.username.label' })}
            register={register({ validate: { required } })}
          />
          {errors.username && (
            <div className={cx('SignUpForm__error')}>{errors.username.message}</div>
          )}
        </div>
        <div className={cx('SignUpForm__field')}>
          <Controller
            name="phone"
            control={control}
            rules={{
              validate: {
                required,
                isValid: () => isPhoneValid(watch('isPhoneValid')),
              },
            }}
            render={({ onChange, value, name, onBlur }) => {
              return (
                <PhoneFieldMultiCountry
                  name={name}
                  value={value}
                  label={intl.formatMessage({ id: 'field.phone.label' })}
                  onChange={async (e, isValid, country_code) => {
                    await setValue('isPhoneValid', isValid);
                    await setValue('country_code', country_code);
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  enableBuildInValidation
                />
              );
            }}
          />
          <input type={'hidden'} {...register('isPhoneValid')} />
          <input type={'hidden'} {...register('country_code')} />
          {errors.phone && <div className={cx('SignUpForm__error')}>{errors.phone.message}</div>}
        </div>
        <div className={cx('SignUpForm__field')}>
          <InputField
            name="email"
            label="Email"
            register={register({ validate: { required, isEmail } })}
            onKeyDown={e => e.key === 'Enter' && onFirstStepSubmit()}
          />
          {errors.email && <div className={cx('SignUpForm__error')}>{errors.email.message}</div>}
        </div>
      </div>
      <div className={cx('SignUpForm__step', { SignUpForm__step_visible: !isFirstStep })}>
        <div className={cx('SignUpForm__field')}>
          <PasswordField
            name="password"
            label={intl.formatMessage({ id: 'field.password.label' })}
            register={register({ validate: { required, strongPassword } })}
          />
          {errors.password && (
            <div className={cx('SignUpForm__error')}>{errors.password.message}</div>
          )}
        </div>
        <div className={cx('SignUpForm__field')}>
          <PasswordField
            name="password_repeat"
            label={intl.formatMessage({ id: 'field.repeatPassword.label' })}
            register={register({
              validate: {
                required,
                passwordIsEqual: value =>
                  getValues('password') === value
                    ? undefined
                    : intl.formatMessage({ id: 'validators.passwordIsEqual' }),
              },
            })}
          />
          {errors.password_repeat && (
            <div className={cx('SignUpForm__error')}>{errors.password_repeat.message}</div>
          )}
        </div>
      </div>
      {(config.legal === 'pl' || config.legal === 'de') && !isFirstStep && (
        <>
          {/*<div className={cx('SignUpForm__toggle-wrapper')}>*/}
          {/*  <CheckboxField*/}
          {/*    className={cx('SignUpForm__privacy-title')}*/}
          {/*    value={isAllSelected}*/}
          {/*    label={intl.formatMessage(*/}
          {/*      {id: 'privacy.checkbox.acceptAll.title'},*/}
          {/*      {*/}
          {/*        link1: (*/}
          {/*          <AppLink to={getRoute('agb')} target={'_blank'}>*/}
          {/*            {intl.formatMessage({id: 'checkout.agreePrivacyPolicyLink'})}*/}
          {/*          </AppLink>*/}
          {/*        ),*/}
          {/*        link2: (*/}
          {/*          <AppLink to={getRoute('politics-of-privacy')} target={'_blank'}>*/}
          {/*            {intl.formatMessage({id: 'checkout.agreePrivacyPolicyLink1'})}*/}
          {/*          </AppLink>*/}
          {/*        ),*/}
          {/*      },*/}
          {/*    )}*/}
          {/*    onChange={acceptAllToggleHandler}*/}
          {/*  />*/}
          {/*    <OpenIcon onClick={() => setIsExpanded(!isExpanded)}*/}
          {/*              className={cx('SignUpForm__toggle-arrow', {'SignUpForm__toggle-arrow_expanded': isExpanded })}*/}
          {/*    />*/}
          {/*</div>*/}

          <div
            className={cx('SignUpForm__accordion', { SignUpForm__accordion_expanded: isExpanded })}
          >
            <CheckboxField
              className={cx('SignUpForm__privacy-title')}
              value={isCheckbox5Enabled}
              label={intl.formatMessage(
                { id: 'privacy.checkbox5.title' },
                {
                  link1: (
                    <AppLink to={getRoute('user-agreement')} target={'_blank'}>
                      {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink' })}
                    </AppLink>
                  ),
                  link2: (
                    <AppLink to={getRoute('politics-of-privacy')} target={'_blank'}>
                      {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink1' })}
                    </AppLink>
                  ),
                },
              )}
              onChange={() => setIsCheckbox5Enabled(prev => !prev)}
            />

            <div className={cx('SignUpForm__privacy-description')}>
              {intl.formatMessage(
                { id: 'privacy.checkbox5.description' },
                {
                  link1: (
                    <AppLink to={getRoute('agb')} target={'_blank'}>
                      {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink' })}
                    </AppLink>
                  ),
                  link2: (
                    <AppLink to={getRoute('politics-of-privacy')} target={'_blank'}>
                      {intl.formatMessage({ id: 'checkout.agreePrivacyPolicyLink1' })}
                    </AppLink>
                  ),
                },
              )}
            </div>
            <CheckboxField
              className={cx('SignUpForm__privacy-title')}
              value={isCheckbox1Enabled}
              label={intl.formatMessage({ id: 'privacy.checkbox1.title' })}
              onChange={() => setIsCheckbox1Enabled(prev => !prev)}
            />
            <div className={cx('SignUpForm__privacy-description')}>
              {intl.formatMessage({ id: 'privacy.checkbox1.description' })}
            </div>
            <div className={cx('SignUpForm__privacy-subtitle')}>
              {intl.formatMessage({ id: 'privacy.title' })}
            </div>
            <div className={cx('SignUpForm__privacy-description')}>
              {intl.formatMessage({ id: 'privacy.description' })}
            </div>
            <CheckboxField
              className={cx('SignUpForm__privacy-title')}
              value={isCheckbox3Enabled}
              label={intl.formatMessage({ id: 'privacy.checkbox3.title' })}
              onChange={() => setIsCheckbox3Enabled(prev => !prev)}
            />
            <div className={cx('SignUpForm__privacy-description')}>
              {intl.formatMessage({ id: 'privacy.checkbox3.description' })}
            </div>
            <CheckboxField
              className={cx('SignUpForm__privacy-title')}
              value={isCheckbox4Enabled}
              label={intl.formatMessage({ id: 'privacy.checkbox4.title' })}
              onChange={() => setIsCheckbox4Enabled(prev => !prev)}
            />
            <div className={cx('SignUpForm__privacy-description')}>
              {intl.formatMessage({ id: 'privacy.checkbox4.description' })}
            </div>
          </div>
        </>
      )}
      <div className={cx('SignUpForm__actions')}>
        {isFirstStep ? (
          <Button
            className={cx('SignUpForm__button')}
            onClick={onFirstStepSubmit}
            type="button"
            isLoading={isSigningUp}
          >
            {intl.formatMessage({ id: 'button.continue' })}
          </Button>
        ) : (
          <>
            <Button
              className={cx('SignUpForm__button', 'SignUpForm__button_left')}
              onClick={() => setIsFirstStep(true)}
              link
            >
              {intl.formatMessage({ id: 'button.back' })}
            </Button>
            <Button
              className={cx('SignUpForm__button')}
              type="submit"
              isLoading={isSigningUp}
              disabled={isButtonDisabled}
            >
              {intl.formatMessage({ id: 'button.continue' })}
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

SignUpForm.defaultProps = {
  className: '',
  successCallback: undefined,
};

SignUpForm.propTypes = {
  className: PropTypes.string,
  successCallback: PropTypes.func,
};
