import _pick from 'lodash/pick';

import * as types from './actionTypes';
import orderApi from './api';

// State
import { clearRestaurantStopList, fetchStopList } from '../restaurant/actions';
import { initGooglePay } from '../payTypes/actions';
import { fetchCartItems } from '../cart/actions';

// Utils
import { plainPhoneNumber } from '../../../utils/telephone';
import { setGlobalMessage } from '../../../utils/setGlobalMessage';

// Config
import config from '../../../config';
import { CLEAR_COMPANY_CHECKOUT_USER_DATA } from './actionTypes';

export const fetchOrderList = (page = 1) => {
  return dispatch => {
    dispatch({ type: types.FETCH_LIST_REQUEST, payload: page });

    return orderApi
      .getList(page)
      .then(data => {
        return dispatch({ type: types.FETCH_LIST_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_LIST_FAILURE, payload: message });
      });
  };
};

export const fetchOrderListIsNeeded = () => {
  return (dispatch, getState) => {
    const {
      order: { isLoading, isLoaded },
      user: { isAuthenticated },
    } = getState();

    return isAuthenticated && !isLoading && !isLoaded ? dispatch(fetchOrderList()) : false;
  };
};

export const fetchOrderListNextPage = () => {
  return (dispatch, getState) => {
    const {
      order: { currentPage },
    } = getState();

    dispatch({ type: types.FETCH_ORDER_LIST_NEXT_PAGE_REQUEST, payload: currentPage + 1 });

    return orderApi
      .getList(currentPage + 1)
      .then(data => {
        return dispatch({ type: types.FETCH_ORDER_LIST_NEXT_PAGE_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_ORDER_LIST_NEXT_PAGE_FAILURE, payload: message });
      });
  };
};

export const setOrderRating = ({ rate, order_id }) => {
  return dispatch => {
    dispatch({ type: types.SET_ORDER_RATING_REQUEST });

    return orderApi
      .setOrderRating({ rate, order_id, comment: '', shortcuts: [] })
      .then(() => {
        return dispatch({ type: types.SET_ORDER_RATING_SUCCESS, payload: { rate, order_id } });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.SET_ORDER_RATING_FAILURE, payload: message });
      });
  };
};

// CHECKOUT

/**
 * Устанавливаем user_data
 * @param {object} payload
 */
export const setCheckoutUserData = payload => ({ type: types.SET_CHECKOUT_USER_DATA, payload });

export const clearCompanyUserData = () => ({ type: types.CLEAR_COMPANY_CHECKOUT_USER_DATA });
/**
 * Устанавливаем deliveryType
 * @param {boolean} payload
 */
export const setCheckoutDeliveryType = payload => {
  return (dispatch, getState) => {
    const {
      deliveryAddress,
      pickupRestaurant,
      previousIsContactFreeDelivery,
    } = getState().order.checkout;

    if (payload === 'delivery') {
      dispatch(setCheckoutContactFreeDelivery(previousIsContactFreeDelivery));
    } else if (payload === 'pickup') {
      dispatch(setCheckoutContactFreeDelivery(false));
    }

    if (payload === 'delivery') {
      dispatch(fetchDeliveryRestaurantDetails(deliveryAddress));
    } else if (payload === 'pickup' && pickupRestaurant) {
      dispatch(setPickupRestaurantDetails(pickupRestaurant));
    } else {
      dispatch(resetCheckoutField('currentRestaurant'));
      dispatch(resetCheckoutField('intervals'));
      dispatch(resetCheckoutField('deliveryTime'));
      dispatch(initGooglePay(false));
      dispatch(clearRestaurantStopList());
    }

    return dispatch({ type: types.SET_CHECKOUT_DELIVERY_TYPE, payload });
  };
};

export const sendCheckoutDeliveryTypeAnalytic = payload => ({
  type: types.SEND_CHECKOUT_DELIVERY_TYPE_ANALYTIC,
  payload,
});

/**
 * Устанавливаем текущий лоялти another_bonuses/pickup_bonuses
 * @param {string} payload
 */
export const setCheckoutBonusesType = payload => ({
  type: types.SET_CHECKOUT_BONUSES_TYPE,
  payload,
});

/**
 * Устанавливаем тип времени доставки instantly: "как можно скорее" или intime: "ко времени"
 * @param {string} payload
 */
export const setCheckoutDeliveryTimeType = payload => ({
  type: types.SET_CHECKOUT_DELIVERY_TIME_TYPE,
  payload,
});

/**
 * Устанавливаем время доставки
 * @param {string} payload
 */
export const setCheckoutDeliveryTime = payload => ({
  type: types.SET_CHECKOUT_DELIVERY_TIME,
  payload,
});

/**
 * Устанавливаем тип оплаты payType (cash/card_to_courier/online)
 * @param {string} payload
 */
export const setCheckoutPayType = payload => ({ type: types.SET_CHECKOUT_PAY_TYPE, payload });

/**
 * Вводим значение сдачи change
 * @param {number} payload
 */
export function setCheckoutChange(payload) {
  return { type: types.SET_CHECKOUT_CHANGE, payload };
}

/**
 * Ставим чекбокс сохранить карту или нет
 * @param {number} payload
 */
export function setCheckoutIsCardSaved(payload) {
  return { type: types.SET_CHECKOUT_IS_CARD_SAVED, payload };
}

/**
 * Переключаем значение isContactFreeDelivery
 * @param {boolean} payload
 */
export function setCheckoutContactFreeDelivery(payload) {
  return { type: types.SET_CHECKOUT_CONTACT_FREE_DELIVERY, payload };
}

/**
 * Устанавливаем примечание для курьера
 * @param {string} payload
 */
export const setCheckoutDescription = payload => ({
  type: types.SET_CHECKOUT_DESCRIPTION,
  payload,
});

// запрашиваем детали ресторана по адресу для доставки, на входе {address_in_line, flat, floor, porch, intercom}
export const setCheckoutDeliveryAddress = params => ({
  type: types.SET_CHECKOUT_DELIVERY_ADDRESS,
  payload: params,
});

//
export const fetchRestaurantIntervals = (params, intervals) => {
  return dispatch => {
    dispatch({ type: types.FETCH_RESTAURANT_INTERVALS_REQUEST });

    return orderApi
      .getIntervals(params)
      .then(payload => {
        if (JSON.stringify(payload) !== JSON.stringify(intervals)) {
          // сбрасываем выбранное время
          return dispatch({ type: types.FETCH_RESTAURANT_INTERVALS_SUCCESS, payload });
        }
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_RESTAURANT_INTERVALS_FAILURE, payload: message });
      });
  };
};

export const fetchRestaurantDeliveryTime = params => {
  return dispatch => {
    dispatch({ type: types.FETCH_RESTAURANT_DELIVERY_TIME_REQUEST });

    const { restaurant_id, address_coordinates } = params;

    return orderApi
      .getRestaurantDeliveryTime({ restaurant_id, address_coordinates, withError: false })
      .then(data => {
        let result = data.delivery_time;

        // результат приходит в формате '45-55 мин', убираем 'мин'
        if (result) {
          result = result
            .split('')
            .filter(char => char.match(/[0-9-]/i))
            .join('');
        }

        return dispatch({ type: types.FETCH_RESTAURANT_DELIVERY_TIME_SUCCESS, payload: result });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_RESTAURANT_DELIVERY_TIME_FAILURE, payload: message });
      });
  };
};

// запрашиваем детали ресторана по адресу для доставки, на входе {address_in_line, flat, floor, porch, intercom}
export const fetchDeliveryRestaurantDetails = (
  address,
  onSuccessCallback,
  isPrePayCheck = false,
) => {
  return dispatch => {
    dispatch({ type: types.FETCH_DELIVERY_RESTAURANT_DETAILS_REQUEST });

    const args = address.address_id
      ? { address_id: address.address_id }
      : { address: address.address_in_line };

    return orderApi
      .getRestaurantDetails(args)
      .then(data => {
        // инициализируем гугл пей (параметр приходит в ресторане)
        dispatch(initGooglePay(data.google_pay));
        // подтягиваем стоплисты для ресторана
        dispatch(fetchStopList(data.id));
        // запрашиваем интервалы доставки при выборе ресторана
        if (!isPrePayCheck) {
          // если это проверка перед оплатой - не запрашиваем
          dispatch(fetchRestaurantIntervals(args));
        }
        //
        if (data.delivery_coordinates) {
          dispatch(
            fetchRestaurantDeliveryTime({
              restaurant_id: data.id,
              address_coordinates: JSON.stringify(data.delivery_coordinates.reverse()),
            }),
          );
        }

        //
        if (onSuccessCallback) {
          onSuccessCallback();
        }

        return dispatch({
          type: types.FETCH_DELIVERY_RESTAURANT_DETAILS_SUCCESS,
          payload: data,
          analytAddress: address.address_in_line,
        });
      })
      .catch(({ message }) => {
        return dispatch({
          type: types.FETCH_DELIVERY_RESTAURANT_DETAILS_FAILURE,
          payload: message,
          analytAddress: address.address_in_line,
        });
      });
  };
};

export const setPickupRestaurantDetails = (restaurant, isPrePayCheck = false) => {
  return dispatch => {
    // инициализируем гугл пей (параметр приходит в ресторане)
    dispatch(initGooglePay(restaurant.pay_params.google_pay));
    // подтягиваем стоплисты для ресторана
    dispatch(fetchStopList(restaurant.id));
    // запрашиваем интервалы доставки при выборе ресторана
    if (!isPrePayCheck) {
      // если это проверка перед оплатой - не запрашиваем
      dispatch(fetchRestaurantIntervals({ restaurant_id: restaurant.id }));
    }

    return dispatch({
      type: types.SET_PICKUP_RESTAURANT_DETAILS,
      payload: restaurant,
    });
  };
};

export const setRestaurantDetailsWithoutTime = () => {
  return (dispatch, getState) => {
    const { pickupRestaurant, deliveryRestaurant, deliveryType } = getState().order.checkout;
    const currentRestaurant = deliveryType === 'pickup' ? pickupRestaurant : deliveryRestaurant;

    // инициализируем гугл пей (параметр приходит в ресторане)
    dispatch(initGooglePay(currentRestaurant.pay_params.google_pay));
    // подтягиваем стоплисты для ресторана
    dispatch(fetchStopList(currentRestaurant.id));

    return dispatch({
      type: types.SET_RESTAURANT_DETAILS_WITHOUT_TIME,
      payload: currentRestaurant,
    });
  };
};

// сбрасываем hoursMinutes
export const setCheckoutHoursMinutes = data => ({
  type: types.SET_CHECKOUT_HOURS_MINUTES,
  payload: data,
});

// запрашиваем детали ресторана по id ресторана для самовывоза, на входе restaurant_id и wanted_time
export const fetchRestaurantDetailsWithTime = wantedTime => {
  return (dispatch, getState) => {
    const { deliveryAddress, pickupRestaurant, deliveryType } = getState().order.checkout;

    dispatch({ type: types.FETCH_RESTAURANT_DETAILS_WITH_TIME_REQUEST });

    const args = deliveryAddress.address_id
      ? { address_id: deliveryAddress.address_id }
      : { address: deliveryAddress.address_in_line };

    const params =
      deliveryType === 'pickup'
        ? { wanted_time: wantedTime, restaurant_id: pickupRestaurant.id }
        : { ...args, wanted_time: wantedTime };

    return orderApi
      .getRestaurantDetails(params)
      .then(data => {
        // инициализируем гугл пей (параметр приходит в ресторане)
        dispatch(initGooglePay(data.pay_params.google_pay));
        // подтягиваем стоплисты для ресторана
        dispatch(fetchStopList(data.id));

        return dispatch({
          type: types.FETCH_RESTAURANT_DETAILS_WITH_TIME_SUCCESS,
          payload: data,
        });
      })
      .catch(({ message }) => {
        return dispatch({
          type: types.FETCH_RESTAURANT_DETAILS_WITH_TIME_FAILURE,
          payload: message,
        });
      });
  };
};

export const setLocalStorageCheckout = params => {
  return (_, getState) => {
    const localStorageCheckout = {
      value: { ...getState().order.checkout, ...params },
      timestamp: new Date().getTime(),
    };

    window.localStorage.setItem('checkout', JSON.stringify(localStorageCheckout));
  };
};

export const removeLocalStorageCheckout = () => {
  window.localStorage.removeItem('checkout');
};

export const getLocalStorageCheckout = () => {
  return (dispatch, getState) => {
    const city_id = getState().city.userCity.id;
    const checkout = JSON.parse(window.localStorage.getItem('checkout'));

    if (!checkout) {
      dispatch({ type: types.GET_LOCAL_STORAGE_CHECKOUT_FAILURE });
      return;
    }

    async function fetchRestaurantDetails() {
      // если промежуток меньше 10 минут, то все норм
      if ((new Date().getTime() - checkout.timestamp) / 1000 >= 600) {
        removeLocalStorageCheckout();
        return;
      }

      const checkoutFields = _pick(checkout.value, [
        'user_data',
        'deliveryType',
        'deliveryTimeType',
        'payType',
        'change',
        'isContactFreeDelivery',
        'previousIsContactFreeDelivery',
        'description',
      ]);

      // для адреса доставки
      if (
        checkout.value.deliveryAddress.address_id ||
        checkout.value.deliveryAddress.address_in_line
      ) {
        const addressArgs = checkout.value.deliveryAddress.address_id
          ? { address_id: checkout.value.deliveryAddress.address_id }
          : { address: checkout.value.deliveryAddress.address_in_line };

        try {
          const restaurantDataWithoutTime = await orderApi.getRestaurantDetails({
            ...addressArgs,
            withError: false,
          });

          // if (restaurantDataWithoutTime.delivery_coordinates) {
          //   const newTime = await orderApi.getRestaurantDeliveryTime({
          //     restaurant_id: restaurantDataWithoutTime.id,
          //     address_coordinates: JSON.stringify(
          //       restaurantDataWithoutTime.delivery_coordinates.reverse(),
          //     ),
          //   });
          //   restaurantDataWithoutTime.delivery_time =
          //     newTime.delivery_time || restaurantDataWithoutTime.delivery_time;
          // }

          // если город тот же самый, то подставляем ресторан доставки и адрес доставки
          if (restaurantDataWithoutTime.city_id === city_id) {
            checkoutFields.deliveryRestaurant = restaurantDataWithoutTime;
            checkoutFields.deliveryAddress = checkout.value.deliveryAddress;
          }
        } catch {
          console.warn('localStorage error');
        }
      }

      // для адреса самовывоза
      if (checkout.value.pickupRestaurant) {
        try {
          const restaurantDataWithoutTime = checkout.value.pickupRestaurant;

          // если мы не меняли город
          if (restaurantDataWithoutTime.city_id === city_id) {
            checkoutFields.pickupRestaurant = restaurantDataWithoutTime;
          }
        } catch {
          console.warn('localStorage error');
        }
      }

      // проверяем, есть ли выбранное время доставки
      const wanted_time =
        checkout.value.deliveryTimeType === 'intime' && checkout.value.deliveryTime.hoursMinutes
          ? `${checkout.value.deliveryTime.day} ${checkout.value.deliveryTime.hoursMinutes}`
          : undefined;

      // если есть, то назначаем его
      if (wanted_time) {
        checkoutFields.deliveryTime = checkout.value.deliveryTime;
      }

      if (checkoutFields.deliveryType === 'delivery' && checkoutFields.deliveryRestaurant) {
        const addressArgs = checkoutFields.deliveryAddress.address_id
          ? { address_id: checkoutFields.deliveryAddress.address_id }
          : { address: checkoutFields.deliveryAddress.address_in_line };

        if (wanted_time) {
          checkoutFields.currentRestaurant = await orderApi.getRestaurantDetails({
            ...addressArgs,
            wanted_time,
            withError: false,
          });
        } else {
          checkoutFields.currentRestaurant = checkoutFields.deliveryRestaurant;
        }

        // интервалы
        dispatch(fetchRestaurantIntervals(addressArgs));
      } else if (checkoutFields.deliveryType === 'pickup' && checkoutFields.pickupRestaurant) {
        if (wanted_time) {
          checkoutFields.currentRestaurant = await orderApi.getRestaurantDetails({
            restaurant_id: checkout.value.pickupRestaurant.id,
            wanted_time,
            withError: false,
          });
        } else {
          checkoutFields.currentRestaurant = checkoutFields.pickupRestaurant;
        }

        // интервалы
        dispatch(fetchRestaurantIntervals({ restaurant_id: checkoutFields.currentRestaurant.id }));
      }

      if (checkoutFields.currentRestaurant) {
        // стоплисты
        dispatch(fetchStopList(checkoutFields.currentRestaurant.id));
        // инициализируем гугл пей (параметр приходит в ресторане)
        // await чтобы гугол пей инициализировался вместе со всеми остальными типами оплаты
        await dispatch(initGooglePay(checkoutFields.currentRestaurant.pay_params.google_pay));
      }

      dispatch({ type: types.GET_LOCAL_STORAGE_CHECKOUT_SUCCESS, payload: checkoutFields });
    }

    fetchRestaurantDetails();
  };
};

/**
 * Устанавливаем isPayed после онлайн оплаты
 * @param {boolean} payload
 */
export const setCheckoutIsPayed = () => ({
  type: types.SET_CHECKOUT_IS_PAYED,
});

export const sendCheckoutCancelOrderAnalytic = payload => ({
  type: types.SEND_CHECKOUT_CANCEL_ORDER_ANALYTIC,
  payload,
});

/**
 * Сбрасываем order/checkout до начальных значений, кроме адресов
 */
export const resetCheckout = () => {
  removeLocalStorageCheckout();

  return { type: types.RESET_CHECKOUT };
};

/**
 * Сбрасываем order/checkout до начальных значений
 */
export const resetCheckoutField = field => ({ type: types.RESET_CHECKOUT_FIELD, payload: field });

export const payOrder = (params = {}, callback, onFailureCallback, callbackSber) => {
  return (dispatch, getState) => {
    const {
      card: { cardSelect },
      user: { isAuthenticated },
      cart: { sailplay_discount },
      order: {
        checkout: {
          user_data,
          deliveryType,
          deliveryAddress,
          deliveryTimeType,
          deliveryTime,
          payType,
          change,
          isCardSaved,
          isContactFreeDelivery,
          description,
          currentRestaurant,
          card_bin,
        },
      },
    } = getState();

    dispatch({ type: types.PAY_REQUEST });

    // Для онлайн оплаты - если не введены данные карты - дальше не идем
    if (
      (cardSelect.cardId === -1 || !cardSelect.cardId) &&
      !cardSelect.cryptogram &&
      (currentRestaurant?.pay_params?.online_pay_system === 4 ||
        currentRestaurant?.pay_params?.online_pay_system === 9) &&
      payType === 'online'
    ) {
      setGlobalMessage(params?.cardError);
      return dispatch({ type: types.PAY_FAILURE });
    }

    // Если тип оплаты поменялся на сбер во время оплаты, прерываем оплату
    if (currentRestaurant?.pay_params?.online_pay_system === 6 && params?.cardError) {
      return dispatch({ type: types.PAY_FAILURE });
    }

    const pay_type =
      payType === 'online' && currentRestaurant?.pay_params?.online_pay_system === 11
        ? 'online_stripe'
        : payType;

    // если сберКнопка
    // const is_button_pay =
    //   payType === 'online' && currentRestaurant?.pay_params?.online_pay_system === 6;

    const is_sber = currentRestaurant?.pay_params?.online_pay_system === 8;
    const is_paygateway = currentRestaurant?.pay_params?.online_pay_system === 7;

    // если введена новая карта или карты вообще нет
    const cloudpayments_data =
      (cardSelect.cardId !== -1 || cardSelect.cardId) && cardSelect.cryptogram
        ? { is_token: 0, card_cryptogram_packet: cardSelect.cryptogram, name: cardSelect.cpName }
        : { is_token: 1, card_id: cardSelect.cardId };

    const newParams = {
      card_bin,
      user_data: isAuthenticated
        ? undefined
        : {
          ...user_data,
          phone: plainPhoneNumber(user_data.phone),
          accepted_loyalty_program:
            (config.legal === 'pl' || config.legal === 'de') ? Number(user_data.accepted_loyalty_program) : undefined,
          accepted_registration: undefined,
        },
      // отправляем только для Польши
      accepted_registration:
        (config.legal === 'pl' || config.legal === 'de') ? Number(user_data.accepted_registration) : undefined,
      address_json:
        deliveryType === 'delivery' && !deliveryAddress.address_id ? deliveryAddress : undefined,
      address_id:
        deliveryType === 'delivery' && deliveryAddress.address_id
          ? deliveryAddress.address_id
          : undefined,
      restaurant_id: deliveryType === 'pickup' ? currentRestaurant?.id : undefined,
      wanted_time:
        deliveryTimeType === 'intime'
          ? `${deliveryTime.day} ${deliveryTime.hoursMinutes}`
          : undefined,
      pay_type,
      change: change && pay_type === 'cash' ? change : undefined,
      cloudpayments_data: pay_type === 'online' && !is_sber ? cloudpayments_data : undefined,
      description: `${
        // eslint-disable-next-line no-nested-ternary
        isContactFreeDelivery
          ? (config.legal === 'pl' || config.legal === 'de')
            ? 'CONTACT-FREE DELIVERY; '
            : 'БЕСКОНТАКТНАЯ ДОСТАВКА; '
          : ''
      }${description}`,
      save_card:
        pay_type === 'online' && (cardSelect.cardId === -1 || cardSelect.cardId === null)
          ? isCardSaved
          : undefined,
      papabonus_charge: sailplay_discount || undefined,
      // sberbank_payment_data: { is_button_pay },
      sberbank_payment_data: is_sber
        ? {
          is_card_pay: true,
        }
        : undefined,
      online_payment_system_id: currentRestaurant?.pay_params?.online_pay_system,
      ...params,
    };

    return orderApi
      .pay(newParams)
      .then(payload => {

        return dispatch({
          type: types.PAY_SUCCESS,
          payload: {
            client_secret: payload?.payment?.client_secret,
            redirect_url: payload?.payment?.redirect_url,
            order_data: payload?.order_data,
            order_id: payload?.order_id,
            isPayed:
              !payload?.payment,
          },
        });
      })
      .catch(({ message }) => {
        if (callback) {
          callback(message);
        }

        return dispatch({ type: types.PAY_FAILURE });
      });
  };
};

// Валидация оплаты от Сбербанка
export const sberPaymentValidation = (order, orderFromApi) => {
  return dispatch => {
    const {
      approvalCode,
      orderNumber,
      panMasked,
      refNum,
      digest,
      currency,
      paymentDate,
      formattedAmount,
      formattedFeeAmount,
      status,
    } = order;

    const formattedOrder = {
      approvalCode,
      orderNumber,
      panMasked,
      refNum,
      digest,
      currency,
      paymentDate,
      formattedAmount,
      formattedFeeAmount,
      status,
    };

    return orderApi
      .sberValidation({ order_data: formattedOrder })
      .then(isValidated => {
        if (isValidated.transaction_status) {
          dispatch({
            type: types.PAY_SUCCESS,
            payload: {
              order_data: orderFromApi.order_data,
              order_id: orderFromApi.order_id,
              isPayed: status === 'DEPOSITED',
            },
          });
          return dispatch(fetchCartItems());
        }

        return dispatch({ type: types.PAY_FAILURE });
      })
      .catch(error => {
        setGlobalMessage(error);
        document.body.querySelector('#iframeScrollWrapper').remove();
        dispatch({ type: types.PAY_FAILURE });
        return dispatch(fetchCartItems());
      });
  };
};

export const applePayConfirmation = (params, onSuccessCallback) => {
  return () => {
    return orderApi
      .applePayConfirmation(params)
      .then(paymenResult => {
        onSuccessCallback(paymenResult);
      })
      .catch(() => {
      });
  };
};

export const applePayConfirmationPGW = (params, onSuccessCallback, onFailCallback) => {
  return () => {
    return orderApi
      .applePayConfirmationPGW(params)
      .then(paymenResult => {
        onSuccessCallback(paymenResult);
      })
      .catch(() => {
        if (onFailCallback) {
          onFailCallback();
        }
      });
  };
};

/**
 * Сбрасываем order/checkout до начальных значений
 */
export const resetCheckoutPayment = () => ({ type: types.RESET_CHECKOUT_PAYMENT });

/**
 * Сбрасываем order до начальных значений
 */
export const resetOrderState = () => ({ type: types.RESET_ORDER_STATE });

export const sendAnalyticOrderData = data => ({
  type: types.SEND_ANALYTIC_ORDER_DATA,
  payload: data,
});

/**
 * Сбрасываем order до начальных значений
 */
export const resetMaxSum = () => ({ type: types.RESET_MAX_SUM });

export const saveCardForMir = cardNumber => ({
  type: types.SAVE_CARD_FOR_MIR,
  payload: cardNumber,
});
