import { combineReducers } from 'redux';
import payTypesReducer from './modules/payTypes/reducer';
import catalogReducer from './modules/catalog/reducer';
import userReducer from './modules/user/reducer';
import cityReducer from './modules/city/reducer';
import giftsReducer from './modules/gifts/reducer';
import bannerReducer from './modules/banner/reducer';
import intlReducer from './modules/intl/reducer';
import locationReducer from './modules/location';
import cartReducer from './modules/cart/reducer';
import restaurantReducer from './modules/restaurant/reducer';
import orderReducer from './modules/order/reducer';
import feedbackReducer from './modules/feedback/reducer';
import stockReducer from './modules/stock/reducer';
import cardReducer from './modules/card/reducer';
import halvesReducer from './modules/halves/reducer';
import addressReducer from './modules/address/reducer';
import freeSaucesReducer from './modules/freeSauces/reducer';
import constructorReducer from './modules/constructor/reducer';
import uiReducer from './modules/ui/reducer';
import pageReducer from './modules/page/reducer';
import comboboxReducer from './modules/combobox/reducer';
import modalConfirmReducer from './modules/modalConfirm/reducer';
import responsiveReducer from './modules/responsive/reducer';
import sailplayReducer from './modules/sailplay/reducer';
import chatReducer from './modules/chat/reducer';
import homeModalReducer from './modules/homeModals/reducer';
import promoReducerReducer from './modules/promo/reducer';

export default combineReducers({
  payTypes: payTypesReducer,
  catalog: catalogReducer,
  user: userReducer,
  city: cityReducer,
  banner: bannerReducer,
  intl: intlReducer,
  location: locationReducer,
  cart: cartReducer,
  restaurant: restaurantReducer,
  order: orderReducer,
  feedback: feedbackReducer,
  stock: stockReducer,
  card: cardReducer,
  halves: halvesReducer,
  address: addressReducer,
  gifts: giftsReducer,
  freeSauces: freeSaucesReducer,
  ui: uiReducer,
  pizzaConstructor: constructorReducer,
  responsive: responsiveReducer,
  page: pageReducer,
  combobox: comboboxReducer,
  modalConfirm: modalConfirmReducer,
  sailplay: sailplayReducer,
  chat: chatReducer,
  homeModal: homeModalReducer,
  promo: promoReducerReducer,
});
