import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

// Router
import { getRoute, paths } from '../../../entry/routes';

// Styles
import styles from './Nav.styl';

const cx = classNames.bind(styles);

export default function Nav(props) {
  const { className } = props;
  const intl = useIntl();

  const footerLinksList = useSelector(state => state.ui.footerLinks.list);
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const _supertag = useSelector(state => state.city.userCity._supertag);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isOwnCity = useSelector(state => state.city.userCity.is_own);

  if (!footerLinksList.length) {
    return null;
  }
  const restaurantsPageLink = {
    name: intl.formatMessage({ id: 'page.restaurants.link' }),
    link: paths.restaurantsList,
    position: 3,
  };
  const signinPageLink = {
    name: intl.formatMessage({ id: 'page.signIn' }),
    link: paths.signin,
    position: 3,
  };
  return (
    <ul className={cx('Nav', className)}>
      {[...footerLinksList, signinPageLink, restaurantsPageLink].sort((a, b) => a.position - b.position).map(item =>
        item.link.includes('http') ? (
          <li className={cx('Nav__item')} key={item.link}>
            <a
              className={cx('Nav__link')}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          </li>
        ) : (
          <li className={cx('Nav__item')} key={item.link}>
            <Link to={getRoute(item.link)} className={cx('Nav__link')}>
              {item.name}
            </Link>
          </li>
        ),
      )}
      {isRealPhone && isAuthenticated && !!isOwnCity && (
        <li>
          <Link to={`/${_supertag}/chat`} className={cx('Nav__link')}>
            {intl.formatMessage({ id: 'chat.button.footer' })}
          </Link>
        </li>
      )}
    </ul>
  );
}

Nav.defaultProps = {
  className: '',
};

Nav.propTypes = {
  className: PropTypes.string,
};
