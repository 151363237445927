import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import qs from 'query-string';

// Components
import Button from '../Button';
import Popup from '../Popup';
import Profile from './Profile';
import Forms from './Forms';

// State
import { signOut } from '../../state/modules/user/actions';
import { toggleAuthPopup } from '../../state/modules/ui/actions';

// Styles
import styles from './AuthModule.styl';
import { useHistory } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function AuthModule(props) {
  const { className, registerForm, shouldScrollTopOnSignIn } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.ui.isAuthPopupOpen);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const history = useHistory();

  const onHide = () => {
    dispatch(toggleAuthPopup(false));
    history.push({ search: null });
  };

  const onSignOut = () => {
    dispatch(signOut());
  };

  const showSignIn = async () => {
    try {
      if (shouldScrollTopOnSignIn) {
        await window.scrollTo({top: 0, behavior: 'instant'});
      }
    } finally {
      dispatch(toggleAuthPopup(true))
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(toggleAuthPopup(false));
    }
    if (registerForm==='open') {
      showSignIn()
    }
  }, [isAuthenticated, registerForm]);

  return (
    <div className={cx('AuthModule', className)} data-test-id='auth_popup'>
      {isAuthenticated ? (
        <Profile onSignOut={onSignOut} />
      ) : (
        <Button
          className={cx('AuthModule__button')}
          onClick={showSignIn}
          secondary
          disabled={isOpen}
        >
          {intl.formatMessage({ id: 'button.login' })}
        </Button>
      )}
      <Popup isOpen={isOpen} onHide={onHide} isAuth>
        <Forms registerForm={registerForm}/>
      </Popup>
    </div>
  );
}

AuthModule.defaultProps = {
  className: '',
  shouldScrollTopOnSignIn: false
};

AuthModule.propTypes = {
  className: PropTypes.string,
  registerForm: PropTypes.string,
  shouldScrollTopOnSignIn: PropTypes.bool
};
