import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Assets
import logoRu from './assets/logo_ru_new.svg';
import logoEn from './assets/logo_en_new.svg';
import ny_logoRu from '../../assets/NY_images/NY_logo.svg';
import ny_logoEn from '../../assets/NY_images/NY_logo_en.svg';

// Config
import config from '../../config';

// Styles
import styles from './Logo.styl';

const cx = classNames.bind(styles);

const logos = {
  ru: logoRu,
  en: logoEn,
  pl: logoEn,
  de: logoEn,
};

const nyLogos = {
  ru: ny_logoRu,
  en: ny_logoEn,
};

export default function Logo(props) {
  const { className, disableThemes } = props;

  const locale = useSelector(state => state.intl.locale);
  const lang = config.legal === 'kz' || config.legal === 'kg' ? 'en' : locale;
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  const logo = isNewYearMode && !disableThemes ? nyLogos[lang] : logos[lang];

  return <img className={cx(className, {'Logo__new_year': isNewYearMode})} src={logo} alt="Papa John's" />;
}

Logo.defaultProps = {
  className: '',
  disableThemes: false,
};

Logo.propTypes = {
  className: PropTypes.string,
  disableThemes: PropTypes.bool,
};
