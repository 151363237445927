import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Components
import Container from '../../Container';
import SocialButton from '../../SocialButton';
import MobilePhone from '../../MobilePhone';
import MobileAppButton from '../../MobileAppButton';
import Nav from '../Nav';

// State
import {
  applicationLinksSelector,
  socialLinksSelector,
  appStoreUrlSelector,
  playMarketUrlSelector,
  appGalleryUrlSelector,
} from '../../../state/modules/city/selectors';

// Router
import { getRoute } from '../../../entry/routes';

// Config
import config from '../../../config';

// Styles
import styles from './Footer.styl';
import { toggleCookiePopup } from '../../../state/modules/ui/actions';

const cx = classNames.bind(styles);

export default function Footer(props) {
  const { className } = props;
  const dispatch = useDispatch();

  const applicationLinks = useSelector(applicationLinksSelector);
  const socialLinks = useSelector(socialLinksSelector);
  const appleStoreUrl = useSelector(appStoreUrlSelector);
  const playMarketUrl = useSelector(playMarketUrlSelector);
  const appGalleryUrl = useSelector(appGalleryUrlSelector);
  const OS = useSelector(state => state.responsive.OS);
  const helpNumber = useSelector(state => state.city.userCity.help_number);
  const locale = useSelector(state => state.intl.locale);

  const currentYear = new Date().getFullYear();

  const getMobileAppButton = () => {
    if (OS === 'iOS' && appleStoreUrl) {
      return <MobileAppButton className={cx('Footer__app')} url={appleStoreUrl} type="apple" />;
    }

    if (OS === 'AndroidOS') {
      return (
        <>
          {playMarketUrl && (
            <MobileAppButton className={cx('Footer__app')} url={playMarketUrl} type="google" />
          )}
          {appGalleryUrl && (
            <MobileAppButton className={cx('Footer__app')} url={appGalleryUrl} type="huawei" />
          )}
        </>
      );
    }

    return null;
  };

  return (
    <footer className={cx('Footer', className)}>
      <Container className={cx('Footer__container')}>
        <div className={cx('Footer__body')}>
          { OS === 'iOS' && <MobilePhone className={cx('Footer__phone')} phone={helpNumber} /> }
          <div className={cx('Footer__row')}>
            <Nav className={cx('Footer__nav')} />
            {/*{applicationLinks.length > 0 && (*/}
            {/*  <div className={cx('Footer__apps')}>{getMobileAppButton()}</div>*/}
            {/*)}*/}
          </div>
          <div className={cx('Footer__row', 'Footer__row_second')}>
            <div className={cx('Footer__copyright')}>
              <span className={cx('Footer__copyright-line')}>
                <FormattedMessage
                  id={`footer.copyright.part_1.${config.legal}`}
                  // values={{ currentYear }}
                />
              </span>
              {config.legal === 'ru' && (
                <>
                  <span className={cx('Footer__copyright-line')}>
                    <FormattedMessage id="footer.copyright.part_4" />
                  </span>
                  <span className={cx('Footer__copyright-line')}>
                    <FormattedMessage id="footer.copyright.part_5" />
                  </span>
                  <span className={cx('Footer__copyright-line')}>
                    <FormattedMessage id="footer.copyright.part_6" />
                  </span>
                </>
              )}
              <span className={cx('Footer__copyright-line')}>
                <FormattedMessage id="footer.copyright.part_2" />
              </span>
              {config.legal !== 'kg' && config.legal !== 'kz' && (
                <span className={cx('Footer__copyright-line')}>
                  <FormattedMessage id="footer.copyright.part_3" />
                </span>
              )}
            </div>
            {socialLinks.length > 0 && (
              <div className={cx('Footer__socials')}>
                {socialLinks.map(link => (
                  <SocialButton
                    className={cx('Footer__social')}
                    key={link.key}
                    type={link.key}
                    href={link.url}
                    locale={locale}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={cx('Footer__legal')}>
          <Link
            className={cx('Footer__legal-link')}
            to={getRoute('politics-of-privacy')}
          >
            <FormattedMessage id="footer.privacyPolicy" />
          </Link>
          <div
            className={cx('Footer__cookie')}
            onClick={() => {
              dispatch(toggleCookiePopup(true));
            }}
          >
            <FormattedMessage id="cookie.request.modal" />
          </div>
        </div>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};
