const prefix = '@ui';

export const FETCH_FOOTER_LINKS_REQUEST = `${prefix}/FETCH_FOOTER_LINKS_REQUEST`;
export const FETCH_FOOTER_LINKS_SUCCESS = `${prefix}/FETCH_FOOTER_LINKS_SUCCESS`;
export const FETCH_FOOTER_LINKS_FAILURE = `${prefix}/FETCH_FOOTER_LINKS_FAILURE`;

export const TOGGLE_HOME_CATEGORIES = `${prefix}/TOGGLE_HOME_CATEGORIES`;
export const TOGGLE_HOME_CATALOG = `${prefix}/TOGGLE_HOME_CATALOG`;
export const TOOGLE_ALCOHOL_WARNING = `${prefix}/TOOGLE_ALCOHOL_WARNING`;
export const TOGGLE_CITY_POPUP = `${prefix}/TOGGLE_CITY_POPUP`;
export const TOGGLE_AUTH_POPUP = `${prefix}/TOGGLE_AUTH_POPUP`;
export const TOGGLE_COOKIE_POPUP = `${prefix}/COOKIE_AUTH_POPUP`;
export const TOGGLE_COOKIE_POLICY_ACCEPT = `${prefix}/COOKIE_POLICY_ACCEPT`;

export const SET_CITY_REDIRECT = `${prefix}/SET_CITY_REDIRECT`;
export const CLEAR_CITY_REDIRECT = `${prefix}/CLEAR_CITY_REDIRECT`;
export const OPEN_PRODUCT_CARD = `${prefix}/OPEN_PRODUCT_CARD`;
