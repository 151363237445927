import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import { post, put, del, get } from '../../../utils/api';
import { plainPhoneNumber } from '../../../utils/telephone';
import { withUnauthorizedToken } from '../../../utils/auth';

export default {
  hasPhone: phone =>
    get({
      url: 'user/has-phone',
      data: { phone: phone.replace(/[^0-9]/gi, '') },
    }),
  hasMail: email =>
    get({
      url: 'user/has-email',
      data: { email },
    }),
  signUp: params =>
    put({
      url: 'profile/signup',
      data: { ...params, phone: plainPhoneNumber(params.phone) },
      proxy: true,
    }),
  signIn: params =>
    post({
      url: 'profile/login',
      data: withUnauthorizedToken({ ...params, phone: plainPhoneNumber(params.phone) }),
      proxy: true,
    }),
  delAccount: () => del({ url: 'user/delete' }),
  signOut: () => del({ url: 'user/logout' }),
  postUserConfirmCode: params =>
    post({
      url: 'profile/confirm-code',
      data: _omitBy(
        { ...params, phone: params.phone ? plainPhoneNumber(params.phone) : undefined },
        _isUndefined,
      ),
      proxy: true,
    }),
  postUserChangePhone: params =>
    post({
      url: 'user/change-phone',
      data: _omitBy(
        { ...params, phone: params.phone ? plainPhoneNumber(params.phone) : undefined },
        _isUndefined,
      ),
    }),
  postUserConfirmationCodeSend: params =>
    post({
      url: 'user/confirm-code',
      data: _omitBy(
        { ...params, phone: params.phone ? plainPhoneNumber(params.phone) : undefined },
        _isUndefined,
      ),
    }),
  recoveryPasswordMail: params =>
    post({
      url: 'profile/recovery-password',
      data: _omitBy(
        { ...params, phone: params.phone ? plainPhoneNumber(params.phone) : undefined },
        _isUndefined,
      ),
      proxy: true,
    }),
  recoveryPasswordPhone: params =>
    post({
      url: 'user/recovery-password',
      data: _omitBy(
        { ...params, phone: params.phone ? plainPhoneNumber(params.phone) : undefined },
        _isUndefined,
      ),
    }),
  changePasswordPhone: params =>
    post({
      url: 'user/change-password',
      data: _omitBy(
        { ...params, phone: params.phone ? plainPhoneNumber(params.phone) : undefined },
        _isUndefined,
      ),
    }),
  getProfile: params => get({ url: 'user/read', data: params }),
  updateProfile: params => post({ url: 'profile/update', data: params, proxy: true }),
  setAvatar: params =>
    post({ url: 'profile/update', type: 'form-data', data: params, proxy: true }),
  postUserConfirmCodeForUnregistered: params =>
    post({ url: 'profile/confirm-code-for-unregistered', data: params, proxy: true }),
  postUserSignupWithSms: params =>
    post({ url: 'profile/signup-with-sms', data: params, proxy: true }),
  postUserLoginWithSms: ({ phone, code }) =>
    post({
      url: 'user/login-with-sms',
      data: withUnauthorizedToken({ code, phone: plainPhoneNumber(phone) }),
      proxy: true,
    }),
};
