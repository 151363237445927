import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Components
import PopupAnswerAboutCity from '../partials/PopupAnswerAboutCity';
import SearchBar from '../partials/SearchBar';
import Modal from '../../Modal';
import NavBar from '../../AuthModule/NavBar';
import Heading from '../../Heading/MobileView';

// Icons
import PinIcon from '../../../icons/pin.svg';

// Styles
import styles from './CitySelector.styl';
import ModalAddress from '../../ModalAddress/MobileView/ModalAddress';

const cx = classNames.bind(styles);

export default function CitySelector(props) {
  const { className, children, moreThanOne } = props;

  const intl = useIntl();
  const name = useSelector(state => state.city.userCity.name);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div className={cx('CitySelector', className)}>
      <button className={cx('CitySelector__toggle')} onClick={handleOpen} type="button">
        <PinIcon className={cx('CitySelector__icon')} />
        <div className={cx('CitySelector__city')}>{name}</div>
      </button>
      <ModalAddress onOpen={handleOpen}/>
      {/*<PopupAnswerAboutCity onOpen={handleOpen} />*/}
      {moreThanOne && (
        <Modal isOpen={isOpen} onClose={handleClose} direction="left">
          <NavBar className={cx('CitySelector__navbar')} onClose={handleClose}>
            <Heading tagName="div" level={4}>
              {intl.formatMessage({ id: 'citySelector.modal.title' })}
            </Heading>
          </NavBar>
          <div className={cx('CitySelector__list')}>
            <SearchBar />
            {children}
          </div>
        </Modal>
      )}
    </div>
  );
}

CitySelector.defaultProps = {
  className: '',
  children: null,
};

CitySelector.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  moreThanOne: PropTypes.bool.isRequired,
};
