import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Alert from '../Alert';
import ModalConfirm from '../ModalConfirm';
import CookiePolicyPopup from '../CookiePolicyPopup';

// Config
import config from '../../config';

// Styles
import styles from './Layout.styl';

import HeaderDesktop from '../Header/DesktopView';
import HeaderMobile from '../Header/MobileView';
import FooterDesktop from '../Footer/DesktopView';
import FooterMobile from '../Footer/MobileView';
import isomorphicCookies from '../../utils/cookie/isomorphicCookies';

const cx = classNames.bind(styles);

export default function Layout(props) {

  const { children, simple, theme, isLanding } = props;
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const Header = isRealPhone ? HeaderMobile : HeaderDesktop;
  const Footer = isRealPhone ? FooterMobile : FooterDesktop;

  return (
    <div
      className={cx('Layout', `Layout_theme_${theme}`, { Layout_simple: isRealPhone && simple })}
    >
      <Header className={cx('Layout__header')} isLanding={isLanding} />
      <div className={cx('Layout__main')}>{children}</div>
      {!isLanding ? <Footer className={cx('Layout__footer')} /> : null}
      <ModalConfirm />
      <Alert />
      {(config.legal === 'pl' || config.legal === 'de') && <CookiePolicyPopup />}
    </div>
  );
}

Layout.defaultProps = {
  simple: true,
  theme: 'primary',
  isLanding: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  simple: PropTypes.bool,
  theme: PropTypes.oneOf(['primary', 'secondary']),
  isLanding: PropTypes.bool,
};
