import React, { createRef, useEffect, useState } from 'react';
import { Map, YMaps } from 'react-yandex-maps';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Suggestions from '../Suggestions';
import InputField from '../../../InputField/MobileView/InputField';
import SuggestionsPapajohns from '../SuggestionsPapajohns';
import { GMaps } from '../../../GoogleMap';

// State
import { mapLocaleSelector } from '../../../../state/modules/intl/selectors';

// Config
import config from '../../../../config';

// Styles
import styles from './AddressesField.styl';
import DeviceDetector from 'device-detector-js';

const cx = classNames.bind(styles);

function AddressesField(props) {

  const state = useSelector(state => state);
  const { userCity, list } = useSelector(state => state.city);

  const mapLocale = mapLocaleSelector(state);

  let inputRef = createRef();

  let resultContainerRef = null;

  const [userAgent, setUserAgent] = useState('');

  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(userAgent)?.device?.type;

  useEffect(() => {
    if (window)
      setUserAgent(window.navigator.userAgent);
  }, []);

  // get
  const suggestCityArray = () => {

    let cityArray = [];

    const { parent_id, name, id } = userCity;

    if (parent_id && parent_id > 0) {
      cityArray = list
        .filter(cityListItem => cityListItem.name !== name && cityListItem.parent_id === id)
        .map(filteredCity => ({
          city: filteredCity.name,
        }));
    }

    cityArray.push({ city: name });

    return cityArray;
  };

  // get
  const suggestKladrArray = () => {

    let kladrArray = [];

    if (userCity) {
      kladrArray = (userCity || {}).suburb_kladr
        .split(';')
        .filter(_kladr => _kladr.trim().length > 0)
        .map(_kladr => ({
          kladr_id: _kladr.trim(),
        }));
    }

    return kladrArray;
  };

  const setResultContainerRef = ref => {
    if (ref) {
      resultContainerRef = ref;
    }
  };

  // в обеих системах нам приходит объект item, у которого нужно взять значение value
  const handleOnSelect = item => {
    const { input, onAddressSelect } = props;

    input.onChange(item?.value);
    onAddressSelect(item?.value);
  };

  // сделаем сброс поля, если мы стерли все символы. Больше он ни для чего не нужен
  const handleOnBlur = (e, item) => {
    const { input, onAddressSelect } = props;

    if (
      (userCity.suggest_service === 'dadata' && !item?.value) ||
      (userCity.suggest_service !== 'dadata' && !e.target.value)
    ) {
      input.onChange('');
      onAddressSelect('');
    }
  };

  const handleApiAvaliable = ymaps => {

    const isMoscow =
      ['moscow', 'balashiha', 'zelenograd', 'korolev', 'mytishchi', 'shelkovo'].indexOf(
        userCity._supertag,
      ) !== -1;

    if (inputRef?.current) {
      ymaps.load(() => {
        const suggestView = new ymaps.SuggestView(inputRef.current, {
          provider: {
            suggest: request => {
              return ymaps.suggest(
                `${isMoscow ? '' : `${userCity.name}, `}${request}`,
                isMoscow && {
                  boundedBy: [
                    [56.062224, 36.610939],
                    [55.132283, 38.415443],
                  ],
                },
              );
            },
          },
          container: resultContainerRef,
        });

        suggestView.events.add('select', e => {
          handleOnSelect(e.get('item'));
        });
      });
    }
  };

  const handleGoogleSuggest = () => {
    if (inputRef?.current) {
      if (userCity.coordinates) {
        const suggService = new google.maps.places.Autocomplete(inputRef.current, {
          types: ['address', { regions: ['postal_code'] }],
          fields: ['name'],
          componentRestrictions: { country: [config.legal] },
        });

        const circle = new google.maps.Circle({
          radius: 100000,
          center: {
            lat: parseFloat(userCity.coordinates[0]),
            lng: parseFloat(userCity.coordinates[1]),
          },
        });

        suggService.setBounds(circle.getBounds());
        suggService.addListener('place_changed', () =>
          handleOnSelect({ value: suggService.getPlace().name }),
        );
      }
    }
  };

  const getSuggests = () => {
    const { input, newest, label, textBlockInput } = props;

    if (config.legal === 'pl' || config.legal === 'de') {

      return (
        <GMaps
          loadingElement={<div />}
          containerElement={<div className={cx('AddressesField__search')} />}
          mapElement={<div />}
          defaultZoom={userCity.zoom}
          defaultCenter={userCity.coordinates}
          getMapRef={handleGoogleSuggest}
        >
          <div className={cx('AddressesField__resultContainer')} ref={setResultContainerRef} />
          <InputField
            {...input}
            className={cx('AddressesField__field')}
            inputClassname={cx('AddressesField__fieldEnd', { 'AddressesField__mobile': (device === 'smartphone') }, input.inputClassname)}
            autoComplete="off"
            _ref={inputRef}
            onChange={e => input.onChange(e.target.value)}
            onBlur={handleOnBlur}
            newest={newest}
            label={label}
            textBlockInput={textBlockInput}
          />
        </GMaps>
      );
    }
    switch (userCity.suggest_service) {
      case 'papajohns': {
        return (
          <div className={cx('AddressesField__search')}>
            <SuggestionsPapajohns
              inputClassName={cx('AddressesField__fieldEnd', { 'AddressesField__mobile': (device === 'smartphone') }, input.inputClassname)}
              input={input}
              onAddressSelect={handleOnSelect}
              onBlur={handleOnBlur}
              newest={newest}
              label={label}
              _ref={inputRef}
            />
          </div>
        );
      }
      case 'dadata': {
        return (
          <div className={cx('AddressesField__search')}>
            <Suggestions
              {...input}
              id={input.name}
              autoComplete="off"
              locations={suggestCityArray().concat(suggestKladrArray())}
              delay={500}
              query={input.value}
              token={config.dadataToken}
              onAddressSelect={handleOnSelect}
              onBlur={handleOnBlur}
              label={label}
              _ref={inputRef}
              className={cx('AddressesField__fieldEnd', (device === 'smartphone') ? 'AddressesField__mobile' : 'AddressesField__desktop', input.inputClassname)}
            />
          </div>
        );
      }
      case 'yandex':
      default: {
        return (
          <YMaps query={{ lang: mapLocale, apikey: config.yandexApiKey }} enterprise>
            <Map
              width="100%"
              height="100%"
              style={{ display: 'none' }}
              state={{ zoom: 10, center: [0, 0] }}
              onLoad={handleApiAvaliable}
              modules={['SuggestView', 'suggest']}
            />
            <div className={cx('AddressesField__search')}>
              <div
                className={cx('AddressesField__resultContainer')}
                ref={setResultContainerRef}
              />
              <InputField
                {...input}
                inputClassname={cx('AddressesField__fieldEnd', { 'AddressesField__mobile': (device === 'smartphone') }, input.inputClassname)}
                autoComplete="off"
                _ref={inputRef}
                onChange={e => input.onChange(e.target.value)}
                onBlur={handleOnBlur}
                newest={newest}
                label={label}
                textBlockInput={textBlockInput}
              />
            </div>
          </YMaps>
        );
      }
    }
  };

  const { className } = props;
  return <div className={cx('AddressesField', className)}>{getSuggests()}</div>;
}


export default AddressesField;

AddressesField.defaultProps = {
  className: '',
  newest: false,
};

AddressesField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  newest: PropTypes.bool,
  textBlockInput: PropTypes.bool,
};
