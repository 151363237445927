import Cookies from 'js-cookie';
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

const removeCookie = cookieList => {
  cookieList.forEach(item => {
    let cookieKey = null;
    Object.keys(Cookies.get()).forEach(elem => {
      if (elem.includes(item)) {
        cookieKey = elem;
      }
    });
    if (cookieKey) {
      isomorphicCookies().remove(cookieKey);
    }
  });
};
export default removeCookie;
