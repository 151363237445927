import { createSelector } from 'reselect';

const cityId = {
  MOSCOW: 1,
};

export const isDiscountCashbackSystemSelector = createSelector(
  state => state.city.userCity.sailplay?.type,
  sailplayType => sailplayType === 'discount' || sailplayType === 'gifts_and_discount',
);

export const isGiftsCashbackSystemSelector = createSelector(
  state => state.city.userCity.sailplay?.type,
  sailplayType => sailplayType === 'gifts' || sailplayType === 'gifts_and_discount',
);

export const onlyMoscowCitiesSelector = createSelector(
  state => state.city.list,
  cities => cities.filter(city => city.parent_id === cityId.MOSCOW),
);

export const otherCitiesSelector = createSelector(
  state => state.city.list,
  cities => cities.filter(city => city.parent_id !== cityId.MOSCOW),
);

export const filteredCitiesByNameSelector = createSelector(
  [state => state.city.list, state => state.city.searchQuery],
  (cities, value) => {
    const query = value.toLowerCase();
    return cities.filter(city => city.name.slice(0, query.length).toLowerCase() === query);
  },
);

export const allLinksSelector = createSelector(
  state => state.city.userCity.links || [],
  links => links.filter(link => Boolean(link.url)),
);

export const socialLinksSelector = createSelector(allLinksSelector, links => {
  return links.filter(link => link.type === 'social');
});

export const applicationLinksSelector = createSelector(allLinksSelector, links => {
  return links.filter(link => link.type === 'application');
});

export const appStoreUrlSelector = createSelector(applicationLinksSelector, links => {
  return links.find(link => link.key === 'apple')?.url;
});

export const playMarketUrlSelector = createSelector(applicationLinksSelector, links => {
  return links.find(link => link.key === 'google')?.url;
});

export const appGalleryUrlSelector = createSelector(applicationLinksSelector, links => {
  return links.find(link => link.key === 'huawei')?.url;
});
