import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// State
import restaurantApi from '../../state/modules/restaurant/api';
import { selectUserCity } from '../../state/modules/city/actions';
import {
  fetchDeliveryRestaurantDetails,
  resetCheckoutField,
  setCheckoutDeliveryAddress,
  setLocalStorageCheckout,
} from '../../state/modules/order/actions';

// Utils
import { formatMinAddress } from '../../utils/formatters';
import { customScrollTo } from '../../utils/customScrollTo';
import { setCityToRedirect } from '../../state/modules/ui/actions';

export default function useAddressSelector(onSuccessCallback) {
  const dispatch = useDispatch();
  const { userCity, list } = useSelector(state => state.city);
  const deliveryAddress = useSelector(state => state.order.checkout.deliveryAddress);

  const buttonRef = useRef(null);
  const [address, setAddress] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    deliveryAddress,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [addressInLineValue, setAddressInLineValue] = useState(address.address_in_line);
  const [isIntercomAndPorchOpened, setIsIntercomAndPorchOpened] = useState(
    Boolean(deliveryAddress.porch || deliveryAddress.intercom),
  );

  const isButtonExist = useMemo(() => Boolean(onSuccessCallback), [onSuccessCallback]);
  const isCompositionExist = useSelector(state => !!state.cart.composition.length);

  const isError = useMemo(
    () => Boolean(address.address_in_line && address.address_in_line !== addressInLineValue),
    [address.address_in_line, addressInLineValue],
  );

  // вводим адрес
  const handleAddressOnChange = e => {
    setAddress({ [e.target.name]: e.target.value });
  };

  // открыть/закрыть домофон
  const handleIntercomAndPorchToggle = () => {
    setIsIntercomAndPorchOpened(prev => !prev);
  };

  // если у нас нет кнопки подтверждения, то менять адрес в стейте при каждом изменении поля
  const handleOnBlur = () => {
    if (!isButtonExist) {
      dispatch(setCheckoutDeliveryAddress(address));
    }
  };

  const fetchRestaurantDetails = async paramsAddress => {
    // ищем ближайший ресторан для этого адреса
    try {
      dispatch(
        setLocalStorageCheckout({ deliveryAddress: paramsAddress, deliveryType: 'delivery' }),
      );

      // если мы выбрали адрес из сохраненных, то внутри есть city_id
      // если адрес из строки поиска, то city_id = undefined
      let resultCityId = paramsAddress.city_id;
      if (!resultCityId) {
        const { city_id } = await restaurantApi.getRestaurantAddress({
          address: paramsAddress.address_in_line,
        });

        resultCityId = city_id;
      }

      // если city_id найденного ресторана не совпадает с нашим текущим
      // то сделать редирект на нужный город
      // если city_id найденного ресторана не совпадает с нашим текущим
      if (resultCityId !== userCity.id) {
        const newCity = list.find(city => city.id === resultCityId);

        if (newCity) {
          // если корзина пуста сделать редирект на нужный город
          // иначе - спросить желает ли пользователь сменить город
          if (isCompositionExist) {
            dispatch(setCityToRedirect(newCity));
            return;
          }
          dispatch(selectUserCity(newCity));
          return;
        }
      }

      setAddress(paramsAddress);
      setAddressInLineValue(paramsAddress.address_in_line);

      const onFetchDeliveryRestaurantDetailsSuccess = () => {
        dispatch(setCheckoutDeliveryAddress(paramsAddress));

        if (isButtonExist) {
          onSuccessCallback();
        }
      };

      // подтянуть данные о ресторане
      await dispatch(
        fetchDeliveryRestaurantDetails(paramsAddress, onFetchDeliveryRestaurantDetailsSuccess),
      );

    } catch (e) {
      console.warn(e);
    }
  };

  // когда выбрали адрес из предложки / на карте / стерли полностью
  const handleOnAddressInLineSelect = value => {
    const newAddress = { address_id: null, address_in_line: value, city_id: undefined };

    if (isButtonExist) {
      setAddress(newAddress);
      setAddressInLineValue(value);
      return;
    }

    // если нет кнопки подтвердить, то надо подтянуть новые данные ресторана
    if (value) {
      // если мы выбрали из предложки новый адрес, то подтянуть данные ресторана
      fetchRestaurantDetails(newAddress);
    } else {
      // если мы стерли поле адрес, то сбросить выбранный ресторан
      dispatch(
        setCheckoutDeliveryAddress({ address_id: null, address_in_line: '', city_id: undefined }),
      );
      dispatch(resetCheckoutField('deliveryRestaurant'));
      dispatch(resetCheckoutField('currentRestaurant'));
    }
  };

  // при нажатии на сохраненный адрес
  const handleSelectSavedAddress = selectedAddress => {
    const { id, flat, floor, porch, intercom, city_id } = selectedAddress;
    const newAddressInLine = formatMinAddress(selectedAddress);
    const newAddress = {
      address_id: id,
      address_in_line: newAddressInLine,
      flat,
      floor,
      porch,
      intercom,
      city_id,
    };

    // если есть кнопка подтвердить, то после нажатия подьехать к ней
    if (isButtonExist) {
      customScrollTo(buttonRef.current);
      setAddress(newAddress);
      setAddressInLineValue(newAddressInLine);
    } else {
      // если этой кнопки нет, значит надо подтянуть данные ресторана
      fetchRestaurantDetails(newAddress);
    }
  };

  // если нажали на кнопку ПОДТВЕРДИТЬ
  const handleOnAccept = async () => {
    // ищем ближайший ресторан для этого адреса
    setIsLoading(true);
    await fetchRestaurantDetails(address);
    setIsLoading(false);
  };

  // если мы откуда-то изменили deliveryAddress в стейте, то изменить и здесь
  useEffect(() => {
    setAddress(deliveryAddress);
    setAddressInLineValue(deliveryAddress.address_in_line);
  }, [deliveryAddress]);

  useEffect(() => {
    if (!isIntercomAndPorchOpened && (address.porch || address.intercom)) {
      setIsIntercomAndPorchOpened(true);
    }
  }, [address.porch, address.intercom]);

  return {
    buttonRef,
    address,
    isLoading,
    addressInLineValue,
    isIntercomAndPorchOpened,
    setAddressInLineValue,
    //
    handleIntercomAndPorchToggle,
    handleAddressOnChange,
    handleOnBlur,
    handleOnAddressInLineSelect,
    handleSelectSavedAddress,
    //
    isButtonExist,
    handleOnAccept,
    isError,
    fetchRestaurantDetails,
  };
}
