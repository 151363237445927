import React from 'react';
import classNames from 'classnames/bind';

//components
import InputField from '../../../InputField';
import Button from '../../../Button';

//icons
import PointerIcon from '../../../../icons/pointer.svg';

//styles
import styles from './ModalChooseCity.styl';

const cx = classNames.bind(styles);

const ModalChooseCity = (props) => {

  const { openCityList, clickInputCity, valueCity, setValueCity, filteredCities, clickCity } = props;
  return (
    <React.Fragment className={cx('ModalChooseCity')}>
      <div className={cx('ModalChooseCity__top')}>
        <InputField
          inputClassname={cx('ModalChooseCity__text-city', { 'ModalChooseCity__text-city_big': openCityList })}
          className={cx({ 'ModalChooseCity__text-city_big': openCityList })}
          onClick={clickInputCity} value={valueCity}
          onChange={(event) => setValueCity(event.target.value)}
          autoFocus={true}
        />

        <Button
          className={cx('ModalChooseCity__change-city-button', { 'ModalChooseCity__change-city-button_close': openCityList })}>
          <PointerIcon />
        </Button>
      </div>
      <ul className={cx('ModalChooseCity__list')}>
        {filteredCities.map(city => {
          return (
            <li key={city.id}
                className={cx('ModalChooseCity__list-item')}
            >
              <button
                onClick={() => clickCity(city)}
              >{city.name}</button>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default ModalChooseCity;
