import React from 'react';
import { useSelector } from 'react-redux';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

// Config
import config from '../../config';

const GMapHOC = withScriptjs(
  withGoogleMap(({ getMapRef, children, defaultCenter, defaultZoom, ...otherProps }) => (
    <GoogleMap
      defaultZoom={defaultZoom}
      defaultCenter={{
        lat: parseFloat(defaultCenter[0]),
        lng: parseFloat(defaultCenter[1]),
      }}
      ref={getMapRef}
      {...otherProps}
    >
      {children}
    </GoogleMap>
  )),
);

export const GMaps = ({ children, ...otherProps }) => {
  // const GOOGLE_API = 'AIzaSyCcZI6wB8D01dshNyD_nPipPI5keW91pFs';
  const GOOGLE_API = 'AIzaSyCWKH7KXKGPTwx2Nh_e-WB1NpSLX7Bfr6g';
  const language = useSelector(state => state.intl.locale);
  const region = config.lang.toUpperCase();

  return (
    <GMapHOC
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=${language}&region=${region}&key=${GOOGLE_API}`}
      {...otherProps}
    >
      {children}
    </GMapHOC>
  );
};
