import React from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";

// Config

// Partials
import { paths } from "./routes";
import useHelmet from "./useHelmet";

// Scenes
import Error404 from "../scenes/Error404";

const fallback = (
  <div
    style={{
      height: "50%",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "inherit",
      zIndex: 1
    }}
  />
);

const AsyncPage = loadable(props => import(`../scenes/${props.page}`), { fallback });
const AsyncPage2 = loadable(props => import(`../scenes/${props.page}/${props.view}`), { fallback });

export default function useRoutes() {
  const { _supertag, sailplay, pages, compositePages, is_allowed } = useSelector(
    state => state.city.userCity
  );
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isRealTablet = useSelector(state => state.responsive.isRealTablet);
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const helmet = useHelmet();

  const getView = (phoneSupport = true, tabletSupport = true) => {
    if (phoneSupport && isRealPhone) {
      return "MobileView";
    }

    if (tabletSupport && isRealTablet) {
      return "TabletView";
    }

    return "DesktopView";
  };

  const defaultRoutes = [
    {
      path: paths.home,
      children:
        is_allowed === 0 ? (
          <AsyncPage2 page="Home/Landing" view={getView()} />
        ) : (
          <AsyncPage2 page="Home" view={getView()} />
        ),
      layout:
        is_allowed === 0
          ? { simple: true, theme: "secondary", isLanding: true }
          : { simple: false, isScrollToTopDisabled: true },
      newest: true,
      helmet: helmet.home
    },
    {
      path: paths.stockGifts,
      children: <AsyncPage2 page="Home" stockRole="rewards" view={getView()} />,
      layout: { simple: false, isScrollToTopDisabled: true },
      newest: true,
      helmet: helmet.home
    },
    {
      path: paths.stockConditions,
      children: <AsyncPage2 page="Home" stockRole="conditions" view={getView()} />,
      layout: { simple: false, isScrollToTopDisabled: true },
      newest: true,
      helmet: helmet.home
    },
    // надо удалить, когда маркетологи перестанут использовать activateCode. попробуем в середине 2021
    {
      path: paths.activateCode,
      children: <AsyncPage2 page="Home" view={getView()} />,
      redirect: true,
      layout: { simple: false, isScrollToTopDisabled: true },
      newest: true,
      helmet: helmet.home
    },
    {
      path: paths.contacts,
      children: <AsyncPage2 page="Contacts" view={getView(true, false)} />,
      layout: { simple: false, theme: "secondary" },
      newest: true,
      helmet: helmet.contacts
    },
    {
      path: paths.order,
      children: <AsyncPage2 page="Checkout" view={getView(true, false)} />,
      layout: { simple: true },
      newest: true,
      helmet: helmet.order
    },
    {
      path: paths.profile,
      children: <AsyncPage page="Profile" />,
      redirect: !isAuthenticated,
      layout: { simple: true },
      helmet: helmet.profile
    },
    {
      path: paths.constructorAliasFromCard,
      children: <AsyncPage2 page="Home" view={getView()} />,
      layout: { simple: true },
      helmet: helmet.home
    },
    {
      path: paths.constructorAlias,
      children: <AsyncPage page="Constructor" />,
      layout: { simple: true },
      helmet: helmet.constructorAlias
    },
    {
      path: paths.constructorAliasNotId,
      children: <AsyncPage page="Constructor" />,
      layout: { simple: true },
      helmet: helmet.constructorAlias
    },
    {
      path: paths.halves,
      children: <AsyncPage page="Halves" />,
      layout: { simple: true },
      helmet: helmet.halves
    },
    {
      path: paths.signin,
      children: <AsyncPage page="SignIn" />,
      layout: { simple: true },
      redirect: isAuthenticated,
      newest: true,
      helmet: helmet.signin
    },
    {
      path: paths.signup,
      children: <AsyncPage page="SignUp" />,
      layout: { simple: true },
      redirect: isAuthenticated,
      newest: true,
      helmet: helmet.signup
    },
    {
      path: paths.registerForm,
      children: <AsyncPage page="SignUp" />,
      layout: { simple: true },
      redirect: !isRealPhone || isAuthenticated,
      newest: true,
      helmet: helmet.signup
    },
    {
      path: paths.recoveryPassword,
      children: <AsyncPage page="RecoveryPassword" />,
      layout: { simple: true },
      redirect: !isRealPhone || isAuthenticated,
      newest: true,
      helmet: helmet.recoveryPassword
    },
    {
      path: paths.address,
      children: <AsyncPage page="Address" />,
      layout: { simple: true },
      redirect: !isRealPhone,
      newest: true,
      helmet: helmet.address
    },
    {
      path: paths.restaurantsList,
      children: <AsyncPage page="RestaurantsList" />,
      layout: { simple: false, theme: 'secondary' },
      newest: true,
      helmet: helmet.restaurantsList,
    },
    {
      path: paths.restaurant,
      children: <AsyncPage page="Restaurant" />,
      layout: { simple: false, theme: 'secondary' },
      newest: true,
      helmet: helmet.restaurant,
    },
    {
      path: paths.stock,
      children: <AsyncPage2 page="StockList" view={getView(true, false)} />,
      layout: { simple: true },
      newest: true,
      helmet: helmet.stock
    },
    {
      path: paths.stockAlias,
      children: <AsyncPage2 page="Stock" view={getView(true, false)} />,
      layout: { simple: true },
      newest: true,
      helmet: helmet.stockAlias
    },
    {
      path: paths.orderTrackingAlias,
      children: <AsyncPage2 page="OrderTracking" view={getView(true, false)} />,
      layout: { simple: true },
      newest: true,
      helmet: helmet.orderTrackingAlias
    },
    {
      path: paths.comboboxAlias,
      children: <AsyncPage page="ComboBox" />,
      layout: { simple: true },
      helmet: helmet.comboboxAlias
    },

    sailplay?.type !== "none" && {
      path: paths.papabonus,
      children: <AsyncPage2 page="PapaBonus" view={getView(true, false)} />,
      layout: { simple: true, theme: "secondary" },
      newest: true,
      helmet: helmet.papabonus
    },
    {
      path: paths.nutrition,
      children: <AsyncPage page="Nutrition" />,
      layout: { simple: false },
      helmet: helmet.nutrition
    },
    {
      path: paths.nutritionAlias,
      children: <AsyncPage page="Nutrition" />,
      layout: { simple: true },
      helmet: helmet.nutritionAlias
    },
    {
      path: paths.chatMobile,
      children: isRealPhone ? <AsyncPage page="ChatMobile" /> : <Error404 />,
      layout: { simple: true, theme: isRealPhone ? "" : "secondary" }
    },
    {
      path: paths.franchising,
      children: <AsyncPage page="Franchising" />,
      layout: { simple: false  },
      helmet: helmet.franchising,
    },
    {
      path: paths.jobs,
      children: <AsyncPage page="Jobs" />,
      layout: { simple: false },
      helmet: helmet.franchising,
    },
    {
      path: paths.deletemyaccount,
      children: <AsyncPage page="DeleteAccount" />,
      layout: {
        simple: false,
      },
    },
  ].filter(Boolean);

  const simpleRoutes = [...pages, ...compositePages]
    // убираем дубли
    .filter(
      (path, index, array) =>
        array.indexOf(path) === index &&
        !defaultRoutes.find(defaultRoute => defaultRoute.path === path)
    )
    // добавляем к каждому простому пути компонент Page
    .map(path => {
      let childrenComponent = <AsyncPage page="Page" alias={path} />;
      if (path.includes("b2b")) {
        childrenComponent = <AsyncPage page="B2b" alias={path} />;
      }
      return {
        path: `/${path}`,
        children: childrenComponent,
        layout: { simple: false, theme: "secondary" },
        helmet: helmet.page
      };
    });

  return [...defaultRoutes, ...simpleRoutes].map(route => ({
    ...route,
    path: route.path === "/" ? `/${_supertag}` : `/${_supertag}${route.path}`
  }));
}
