import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// State
import { toggleCityPopup } from '../../../../state/modules/ui/actions';

// Utils
import isomorphicCookies from '../../../../utils/cookie/isomorphicCookies';

// Styles
import styles from './PopupAnswerAboutCity.styl';

const cx = classNames.bind(styles);

export default function PopupAnswerAboutCity(props) {
  const { onOpen } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.ui.isCityPopupOpen);
  const name = useSelector(state => state.city.userCity.name);

  useEffect(() => {
    if (!isomorphicCookies().get('city_confirm')) {
      dispatch(toggleCityPopup(true));
    }
  }, []);

  /**
   * Когда у пользователя город верен
   * и нужно запомнить это
   */
  const onCorrectCity = () => {
    isomorphicCookies().set('city_confirm', '1', { expires: 7 });
    dispatch(toggleCityPopup(false));
  };

  /**
   * Когда у пользователя город НЕверен
   * и ему необходимо выбрать другой и запомнить это
   */
  const onIncorectCity = () => {
    onOpen(); // открыть список городов
    dispatch(toggleCityPopup(false)); // закрыть сообщение
    isomorphicCookies().remove('city_confirm');
  };

  /**
   * показываем сообщение если сообщение не закрывали и не выбран город
   */
  return (
    <div className={cx('PopupAnswerAboutCity', { PopupAnswerAboutCity_invisible: !isOpen })}>
      <h2 className={cx('PopupAnswerAboutCity__title')}>
        {intl.formatMessage({ id: 'city.selector.title' })}
        {` ${name}?`}
      </h2>
      <div className={cx('PopupAnswerAboutCity__buttons')}>
        <button onClick={onCorrectCity} className={cx('PopupAnswerAboutCity__yes')} type="button">
          <span>{intl.formatMessage({ id: 'question.yes' })}</span>
        </button>
        <button onClick={onIncorectCity} className={cx('PopupAnswerAboutCity__no')} type="button">
          <span>{intl.formatMessage({ id: 'question.no' })}</span>
        </button>
      </div>
    </div>
  );
}

PopupAnswerAboutCity.propTypes = {
  onOpen: PropTypes.func.isRequired,
};
