import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {Controller, useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import InputField from '../../InputField';
import PhoneField from '../../PhoneField';
import AppLink from '../../AppLink';
import Button from '../../Button';

// State
import {signIn} from '../../../state/modules/user/actions';

// Utils
import {isPhoneValid, required, requiredNumber} from '../../../utils/validators';

// Styles
import styles from './SignInForm.styl';
import {useHistory} from 'react-router-dom';
import PhoneFieldMultiCountry from "../../PhoneFieldMultiCountry";

const cx = classNames.bind(styles);

export default function SignInForm(props) {
  const {className, goToRecoveryPassword, goToSignUp, successCallback} = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const {register, handleSubmit, errors, control, setValue, watch} = useForm({
    defaultValues: {phone: '', password: '', isPhoneValid: false, country_code: ''},
  });

  const onSubmit = handleSubmit(async formValues => {
    const redir = sessionStorage.getItem('redirect');
    setIsLoading(true);
    await dispatch(signIn(formValues, successCallback));
    setIsLoading(false);
    if (redir && redir.length) {
      history.push(redir);
      sessionStorage.setItem('redirect', '');
    }
  });

  useEffect(() => {
    if (window.PasswordCredential) {
      navigator.credentials
        .get({password: true, mediation: 'required'})
        .then(credential =>
          credential
            ? dispatch(signIn({phone: credential.id, password: credential.password}))
            : false,
        );
    }
  }, []);

  return (
    <form className={cx('SignInForm', className)} onSubmit={onSubmit}>
      <div className={cx('SignInForm__phone')}>
        <Controller
          name="phone"
          control={control}
          rules={{
            validate: {required}
          }}
          render={({onChange, value, name}) => (
            <PhoneFieldMultiCountry
              name={name}
              value={value}
              label={intl.formatMessage({id: 'field.phone.label'})}
              onChange={async (e, isValid, country_code) => {
                await setValue('country_code', country_code)
                await setValue('isPhoneValid', isValid)
                onChange(e)
              }}
              autoFocus
            />
          )}
        />
        <input type={'hidden'} {...register('isPhoneValid')}/>
        <input type={'hidden'} {...register('country_code')}/>
        <span className={cx('SignInForm__error')}>{errors.phone?.message}</span>
      </div>

      <div className={cx('SignInForm__password')}>
        <InputField
          name="password"
          type="password"
          label={intl.formatMessage({id: 'field.password.label'})}
          register={register({validate: {required}})}
        />
        <span className={cx('SignInForm__error')}>{errors.password?.message}</span>
      </div>
      {goToSignUp && goToRecoveryPassword && (
        <AppLink className={cx('SignInForm__recovery')} href="#" onClick={goToRecoveryPassword}>
          {intl.formatMessage({id: 'link.recoveryPassword'})}
        </AppLink>
      )}
      <div className={cx('SignInForm__actions')}>
        {goToSignUp ? (
          <Button onClick={goToSignUp} className={cx('SignInForm__link')} link>
            {intl.formatMessage({id: 'link.signUp'})}
          </Button>
        ) : (
          <Button onClick={goToRecoveryPassword} className={cx('SignInForm__link')} link>
            {intl.formatMessage({id: 'link.forgotPassword'})}
          </Button>
        )}
        <Button
          className={cx('SignInForm__submit')}
          type="submit"
          isLoading={isLoading}
        >
          {intl.formatMessage({id: 'button.signIn'})}
        </Button>
      </div>
    </form>
  );
}

SignInForm.defaultProps = {
  className: '',
  goToRecoveryPassword: undefined,
  goToSignUp: undefined,
  successCallback: undefined,
};

SignInForm.propTypes = {
  className: PropTypes.string,
  goToRecoveryPassword: PropTypes.func,
  goToSignUp: PropTypes.func,
  successCallback: PropTypes.func,
};
