/* eslint-disable max-lines */
import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const initialState = {
    recoveryPassword: {
      phone: '',
      email: '',
      codeIsSending: false,
      codeIsSended: false,
      isRecovering: false,
      isRecovered: false,
      error: '',
    },
    recoveryPhone: {
      codeIsSending: false,
      codeIsSended: false,
      error: '',
    },
    signOut: {
      isLoading: false,
      isLoaded: false,
      error: '',
    },
    updateProfile: {
      isLoading: false,
      isLoaded: false,
      error: '',
    },
    isAuthenticated: false,
    profile: {},
    calculateBonuses: {
      papaBonus: 0,
      superBonus: 0
    }
  }
;

/* eslint-disable complexity */
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }

    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case types.SIGNUP_WITH_SMS_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case types.SIGNIN_WITH_SMS_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case types.SIGNOUT_REQUEST:
      return {
        ...state,
        signOut: {
          ...state.signOut,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };

    //

    case types.SIGNOUT_SUCCESS:
      return {
        ...initialState,
      };

    case types.SIGNOUT_FAILURE:
      return {
        ...state,
        signOut: {
          ...state.signOut,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case types.RECOVERY_PHONE_CONFIRM_SMS_REQUEST:
      return {
        ...state,
        recoveryPhone: {
          ...state.recoveryPhone,
          codeIsSending: true,
          codeIsSended: false,
          error: '',
        },
      };

    case types.RECOVERY_PHONE_CONFIRM_SMS_SUCCESS:
      return {
        ...state,
        recoveryPhone: {
          ...state.recoveryPhone,
          codeIsSending: false,
          codeIsSended: true,
          error: '',
        },
      };

    case types.RECOVERY_PHONE_CONFIRM_SMS_FAILURE:
      return {
        ...state,
        recoveryPhone: {
          ...state.recoveryPhone,
          codeIsSending: false,
          codeIsSended: false,
          error: action.error,
        },
      };

    case types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_REQUEST:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          codeIsSending: true,
          codeIsSended: false,
          error: '',
        },
      };

    case types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          codeIsSending: false,
          codeIsSended: true,
          error: '',
        },
      };

    case types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_FAILURE:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          codeIsSending: false,
          codeIsSended: false,
          error: action.error,
        },
      };

    case types.RECOVERY_PASSWORD_SEND_CONFIRM_CODE_RESET:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          codeIsSending: false,
          codeIsSended: false,
          error: '',
        },
      };

    case types.RECOVERY_PASSWORD_REQUEST:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          isRecovering: true,
          isRecovered: false,
          error: '',
        },
      };

    case types.RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          isRecovering: false,
          isRecovered: true,
          error: '',
        },
      };

    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          isRecovering: false,
          isRecovered: true,
          error: '',
        },
      };

    case types.RECOVERY_PASSWORD_FAILURE:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          isRecovering: false,
          isRecovered: false,
          error: action.error,
        },
      };

    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          isRecovering: false,
          isRecovered: false,
          error: action.error,
        },
      };
    case types.RECOVERY_PASSWORD_RESET:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          isRecovering: false,
          isRecovered: false,
          codeIsSending: false,
          codeIsSended: false,
          error: '',
        },
      };

    case types.RECOVERY_PASSWORD_REMEMBER_PHONE:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          phone: action.payload,
        },
      };

    case types.RECOVERY_PASSWORD_REMEMBER_EMAIL:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          email: action.payload,
        },
      };

    case types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };

    case types.FETCH_PROFILE_FAILURE:
      return {
        ...state,
        profile: {},
      };

    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfile: {
          ...state.signIn,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };

    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data,
        updateProfile: {
          ...state.signIn,
          isLoading: false,
          isLoaded: true,
          error: '',
        },
        recoveryPhone: initialState.recoveryPhone,
      };

    case types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfile: {
          ...state.signIn,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case types.UPDATE_PROFILE_RESET:
      return {
        ...state,
        updateProfile: {
          isLoading: false,
          isLoaded: false,
          error: '',
        },
      };

    case types.SET_BONUSES: {
      return immutable.set(state, 'profile.loyalty.points.confirmed', action.payload);
    }

    case types.CLEAR_USER_ERRORS: {
      let outState = { ...state };

      if (typeof action.payload === 'object') {
        action.payload.forEach(key => {
          outState[key] = { ...initialState[key] };
        });
      } else {
        outState = immutable.assign(outState, action.payload, initialState[action.payload]);
      }

      return outState;
    }
    case types.CHANGE_BONUSES: {
      return {...state, calculateBonuses: action.payload}
    }
    default:
      return state;
  }
}
