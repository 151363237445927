import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// State
import { bonusesInCompositionSelector, isAllCompositionPromoSelector } from '../../../state/modules/cart/selectors';
import { sendCheckoutDeliveryTypeAnalytic, setCheckoutDeliveryType } from '../../../state/modules/order/actions';

// Icons
import GiftIcon from '../../../icons/gift.svg';

// Styles
import styles from './DeliveryTypeSwitcher.styl';

const cx = classNames.bind(styles);

export default function DeliveryTypeSwitcher(props) {
  const { className, withAnalytic } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const bonusesInComposition = useSelector(bonusesInCompositionSelector);
  const isAllCompositionPromo = useSelector(isAllCompositionPromoSelector);
  const unit = useSelector(state => state.city.userCity.currency?.unit);
  const pickupBonuses = useSelector(state => state.stock.unconditionalList.data.rewards?.[0]);
  const deliveryType = useSelector(state => state.order.checkout.deliveryType);
  const sailplay_discount = useSelector(state => state.cart.sailplay_discount);
  const stockCode = useSelector(state => state.stock.data.code);
  const has_takeaway_discount = useSelector(
    state => state.order.checkout.currentRestaurant?.has_takeaway_discount,
  );
  const deliveryTypes = [
    { label: intl.formatMessage({ id: 'order.delivery.title' }), value: 'delivery' },
    { label: intl.formatMessage({ id: 'order.pickup.title' }), value: 'pickup' },
  ];
  const onButtonClick = newValue => {
    if (newValue !== deliveryType) {
      dispatch(setCheckoutDeliveryType(newValue));

      if (withAnalytic) {
        dispatch(sendCheckoutDeliveryTypeAnalytic(newValue));
      }
    }
  };

  const isBonusesUsed = Boolean(sailplay_discount) || bonusesInComposition.length > 0;
  const isPromoUsed = isBonusesUsed || stockCode || isAllCompositionPromo;

  useEffect(() => {
    if (!deliveryType) {
      dispatch(setCheckoutDeliveryType('delivery'));
    }
  }, [deliveryType]);

  return (
    <div className={cx('DeliveryTypeSwitcher', className)}>
      <div
        className={cx('DeliveryTypeSwitcher__selector', {
          DeliveryTypeSwitcher__selector_pickup: deliveryType === 'pickup',
        })}
      />
      {deliveryTypes.map(({ label, value }) => (
        <button
          key={label}
          className={cx('DeliveryTypeSwitcher__item', {
            DeliveryTypeSwitcher__item_active: deliveryType === value,
          })}
          onClick={() => onButtonClick(value)}
          onKeyDown={e => e.key === 'Enter' && onButtonClick(value)}
          type="button"
        >
          <div className={cx('DeliveryTypeSwitcher__subtitle')}>{label}</div>
          {value === 'pickup' && pickupBonuses && has_takeaway_discount && (
            <div
              className={cx('DeliveryTypeSwitcher__promo', {
                DeliveryTypeSwitcher__promo_grey: isPromoUsed,
              })}
            >
              {pickupBonuses.reward === 'order_discount' ? (
                `${pickupBonuses.reward_value}${
                  pickupBonuses.type === 'percent' ? '%' : ` ${unit}`
                }`
              ) : (
                <GiftIcon />
              )}
            </div>
          )}
        </button>
      ))}
    </div>
  );
}

DeliveryTypeSwitcher.defaultProps = {
  className: '',
  withAnalytic: false,
};

DeliveryTypeSwitcher.propTypes = {
  className: PropTypes.string,
  withAnalytic: PropTypes.bool,
};
