import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { createIntl, RawIntlProvider, createIntlCache } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';

// Components
import App from './App';

// State
import configureStore from '../state/store';

// Utils
import setCookiesFromGetParams from '../utils/cookie/paramsToCookies';
import isomorphicCookies from '../utils/cookie/isomorphicCookies';

// Config
import config from '../config';

const cache = createIntlCache();

function renderApp(AppComponent) {
  const supportsHistory = 'pushState' in window.history;
  const preloadedState = window.__PRELOADED_STATE__;
  delete window.__PRELOADED_STATE__;

  const store = configureStore(preloadedState);
  const { intl, city } = store.getState();

  const intlValue = createIntl(intl, cache);

  try {
    // удаляем старые куки, которые стояли для домена .papajohns.ru
    isomorphicCookies().set('city_id', '1', {
      expires: -1,
      domain: `.papajohns.${config.legal}`,
    });

    // меняем куку на клиенте на выбранный город. нужно, когда мы переключились на другой город
    // а затем нажали кнопку назад в браузере - сср не задействуется
    const splitPathname = window.location.pathname.split('/');
    // находим город в списке городов
    const urlCity = city.list.find(c => splitPathname[1] === c._supertag);
    // находим его id
    const urlCityId = urlCity ? urlCity.id : null;
    // если в урле один город, а в куках другой, то спрашиваем поп-апом - наш ли это город
    const city_id = Number(isomorphicCookies().get('city_id'));
    if (urlCityId && urlCityId !== city_id) {
      isomorphicCookies().remove('city_confirm');
    }
    // id из урла приоритетнее
    const selectedCityId = urlCityId || city_id;

    isomorphicCookies().set('city_id', selectedCityId, { expires: 7 });
    // конец установки куки города

    const pj_clientId = isomorphicCookies().get('pj_clientId');
    const clientId = window.dataLayer.map(dl => dl.clientID).find(clientID => clientID);
    if (clientId?.length > 0 && clientId !== pj_clientId) {
      isomorphicCookies().set('pj_clientId', clientId);
    }

    const pj_sessionId = isomorphicCookies().get('pj_sessionId');
    const sessionId = window.dataLayer.map(dl => dl.sessionID).find(sessionID => sessionID);
    if (sessionId?.length > 0 && sessionId !== pj_sessionId) {
      isomorphicCookies().set('pj_sessionId', sessionId);
    }

    setCookiesFromGetParams();
  } catch (e) {
    console.log('Error when writing GA clientID to cookie', e);
  }

  loadableReady(() => {
    const root = document.getElementById('root');
    hydrate(
      <Provider store={store}>
        <RawIntlProvider value={intlValue}>
          <BrowserRouter forceRefresh={!supportsHistory}>
            <AppComponent />
          </BrowserRouter>
        </RawIntlProvider>
      </Provider>,
      root,
    );
  });
}

if (!('scrollBehavior' in document.documentElement.style)) {
  import('smoothscroll-polyfill').then(smoothscroll => {
    smoothscroll.polyfill();
  });
}

if (!window.Intl) {
  require.ensure(
    [
      'intl',
      'intl/locale-data/jsonp/ru.js',
      'intl/locale-data/jsonp/en.js',
      '@formatjs/intl-pluralrules/polyfill.js',
      '@formatjs/intl-pluralrules/locale-data/en.js',
      '@formatjs/intl-pluralrules/locale-data/ru.js',
    ],
    require => {
      require('intl');
      require('intl/locale-data/jsonp/ru.js');
      require('intl/locale-data/jsonp/en.js');
      require('@formatjs/intl-pluralrules/polyfill.js');
      require('@formatjs/intl-pluralrules/locale-data/en.js');
      require('@formatjs/intl-pluralrules/locale-data/ru.js');
      renderApp(App);
    },
  );
} else if (!window.Intl.PluralRules) {
  require.ensure(
    [
      '@formatjs/intl-pluralrules/polyfill.js',
      '@formatjs/intl-pluralrules/locale-data/en.js',
      '@formatjs/intl-pluralrules/locale-data/ru.js',
    ],
    require => {
      require('@formatjs/intl-pluralrules/polyfill.js');
      require('@formatjs/intl-pluralrules/locale-data/en.js');
      require('@formatjs/intl-pluralrules/locale-data/ru.js');
      renderApp(App);
    },
  );
} else {
  renderApp(App);
}
