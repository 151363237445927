import * as types from './actionTypes';

const initialState = {
  promoSources: [],
  coupon: null
};

export default function promoReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_COUPON_SOURCES_SUCCESS:
      return {...state, promoSources: action.payload}
    case types.GENERATE_COUPON_SUCCESS:
      return {...state, coupon: action.payload}
    default:
      return state;
  }
}
