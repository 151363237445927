import _isEmpty from 'lodash/isEmpty';

import * as types from './actionTypes';
import stockApi from './api';

// State
import { changeCartSailplayDiscount, updateCartItems } from '../cart/actions';
import { getDeliveryAreaIdSelector } from '../order/selectors';

// Utils
import { setGlobalMessage } from '../../../utils/setGlobalMessage';
import { setUnauthorizedToken } from '../../../utils/auth';

export const fetchStockList = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_LIST_REQUEST });

    return stockApi
      .getStockList()
      .then(data => {
        return dispatch({ type: types.FETCH_LIST_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_LIST_FAILURE, payload: message });
      });
  };
};

export const fetchStockListIfNeeded = () => {
  return (dispatch, getState) => {
    const { isLoading, isLoaded } = getState().stock.list;

    return !isLoading && !isLoaded ? dispatch(fetchStockList()) : false;
  };
};

function applyStockDone(payload) {
  return { type: types.APPLY_STOCK_SUCCESS, payload };
}

function applyStockGifts(payload) {
  return { type: types.APPLY_STOCK_GIFTS, payload };
}

export const applyStock = ({ stock_code, gifts = [], withCartUpdate = true }) => {
  return async (dispatch, getState) => {
    const sailPlayBonuses = getState().cart?.sailplay_discount;

    if (sailPlayBonuses) {
      await dispatch(changeCartSailplayDiscount(0));
    }

    dispatch({ type: types.APPLY_STOCK_REQUEST });

    const { stock, order } = getState();

    return stockApi
      .postStockApply({
        stock_code,
        gifts: [...(stock?.gifts || []), ...gifts],
        restaurant_id: order.checkout.currentRestaurant?.id,
        delivery_area_id: getDeliveryAreaIdSelector(getState()),
      })
      .then(data => {
        dispatch(applyStockDone(data));

        if (data.cart) {
          if (withCartUpdate) {
            dispatch(updateCartItems(data.cart));
          }

          if (data.cart.unauthorized_token) {
            setUnauthorizedToken(data.cart.unauthorized_token);
          }
        }
        if (!_isEmpty(data.details.selected)) {
          dispatch(applyStockGifts(data.details.selected));
        }
      })
      .catch(error => {
        return dispatch({ type: types.APPLY_STOCK_FAILURE, payload: null });
      });
  };
};

export const addReward = (rewardIdx, gifts = [], replaceGift = false) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_REWARD_REQUEST });
    // Подарки, которые уже добавлены в корзину
    const addedGifts = getState().stock.data?.details?.selected;
    // При повторном запросе необходимо отправить айди ресторана для проверки на стоп листы
    const restaurant_id = getState().order.checkout.currentRestaurant?.id;

    if (!_isEmpty(gifts)) {
      dispatch(applyStockGifts(gifts));
    }

    let mergeGifts = [];

    if (replaceGift) {
      // Замена в массиве по индексу и вставка в то место gifts
      mergeGifts = addedGifts
        .slice(0, rewardIdx)
        .concat(gifts)
        .concat(addedGifts.slice(rewardIdx + 1));
    } else {
      mergeGifts = [...addedGifts, ...gifts];
    }

    return stockApi
      .postStockApply({
        stock_code: getState().stock.data?.code,
        gifts: mergeGifts,
        restaurant_id,
        delivery_area_id: getDeliveryAreaIdSelector(getState()),
      })
      .then(data => {
        dispatch(applyStockDone(data));
        dispatch(updateCartItems(data?.cart));
        return dispatch({ type: types.ADD_REWARD_SUCCESS });
      })
      .catch(() => {
        return dispatch({ type: types.ADD_REWARD_FAILURE });
      });
  };
};

export const sendStockApplyExtra = ({ card_id, card_number }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.SEND_STOCK_APPLY_EXTRA_REQUEST });

    return stockApi
      .postStockApplyExtra({
        card_id,
        card_number,
        delivery_area_id: getDeliveryAreaIdSelector(getState()),
      })
      .then(data => {
        if (data.success !== false) {
          setGlobalMessage(data.details?.rewards?.[0]?.message, { type: 'success' });
        }

        if (data.cart) {
          dispatch(updateCartItems(data.cart));

          if (data.cart.unauthorized_token) {
            setUnauthorizedToken(data.cart.unauthorized_token);
          }
        }

        return dispatch({ type: types.SEND_STOCK_APPLY_EXTRA_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.SEND_STOCK_APPLY_EXTRA_FAILURE, payload: message });
      });
  };
};

export function clearStockDone() {
  return { type: types.CLEAR_STOCK_SUCCESS };
}

export const clearStock = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_STOCK_REQUEST });

    return stockApi
      .postStockClear({ delivery_area_id: getDeliveryAreaIdSelector(getState()) })
      .then(data => {
        dispatch(updateCartItems(data));
        return dispatch(clearStockDone());
      })
      .catch(({ message }) => {
        return dispatch({ type: types.CLEAR_STOCK_FAILURE, payload: message });
      });
  };
};

export const getUnconditionalList = () => {
  return dispatch => {
    dispatch({ type: types.GET_UNCONDITIONAL_LIST_REQUEST });

    return stockApi
      .getStockUnconditionalList()
      .then(data => {
        return dispatch({ type: types.GET_UNCONDITIONAL_LIST_SUCCESS, payload: data[0].details });
      })
      .catch(({ message }) => {
        return dispatch({
          type: types.GET_UNCONDITIONAL_LIST_FAILURE,
          payload: { error: message },
        });
      });
  };
};

/**
 * Удаляет ингредиент
 *
 * @param {number} goodId id товара
 * @param {number} variationId id вариации
 * @param {number} index индекс условия промокода, из которого берется текущий товар
 * @param {number} ingredientId id ингредиента
 * @param {text} stockRole 'conditions' or 'rewards'
 */
export const removeIngredient = (goodId, variationId, index, ingredientId, stockRole) => ({
  type: types.REMOVE_INGREDIENT,
  payload: { goodId, variationId, index, ingredientId, stockRole },
});

/**
 * Отменяем удаление ингредиентов
 *
 * @param {number} id - id товара
 * @param {number} variationId id вариации
 * @param {number} index индекс условия промокода, из которого берется текущий товар
 * @param {text} stockRole 'conditions' or 'rewards'
 */
export const cancelRemovedIngredients = (goodId, variationId, index, stockRole) => ({
  type: types.CANCEL_REMOVED_INGREDIENTS,
  payload: { goodId, variationId, index, stockRole },
});

export const clearStockErrors = () => {
  return (dispatch, getState) => {
    if (getState().stock.error) {
      return dispatch({ type: types.CLEAR_STOCK_ERRORS_SUCCESS });
    }

    return false;
  };
};
