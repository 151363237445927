import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

import config from '../../../config';

const initialState = {
  list: [],
  currentPage: 1,
  isLoading: false,
  isLoaded: false,
  error: '',
  checkout: {
    // информация о пользователе
    user_data: {
      username: '',
      phone: '',
      email: '',
      company: '',
      department: '',
      // для Польши попросили убрать дефолтные значения
      subscription_state: config.legal !== 'pl',
      sms_state: false,
      // а это только для Польши
      accepted_registration: false,
      accepted_loyalty_program: false,
    },
    // тип доставки - доставка/самовывоз
    deliveryType: 'delivery',
    // адрес доставки - нужен при выборе тип доставки "доставка"
    deliveryAddress: {
      address_id: null,
      address_in_line: '',
      flat: '',
      floor: '',
      porch: '',
      intercom: '',
    },
    // данные ресторана самовыоза - нужен при выборе типа доставки "самовывоз"
    deliveryRestaurant: null,
    // данные ресторана самовыоза - нужен при выборе типа доставки "самовывоз"
    pickupRestaurant: null,
    // данные текущего ресторана в зависимости от типа доставки
    currentRestaurant: null,
    // тип бонусной скидки - бонусы/подарки или скидка за самовывоз
    bonusesType: 'another_bonuses',
    // тип времени доставки - моментально/ко времени
    deliveryTimeType: 'instantly',
    // доступные интервалы ресторана для заказа ко времени
    intervals: null,
    // время доставки - при выборе типа "ко времени"
    deliveryTime: { day: '', hoursMinutes: '' },
    // способ оплаты - наличные/оплата курьеру/оплата картой/гуглпей/эплпей
    payType: 'cash',
    // сдача - при выборе оплаты наличными
    change: '',
    // сохранить карту - при выборе оплаты картой
    isCardSaved: false,
    // бесконтактная доставка
    isContactFreeDelivery: false,
    previousIsContactFreeDelivery: null,
    // комментарий курьеру
    description: '',
    // 3 поля прилетают в ответе order/save при нажатии на оформление заказа
    order_data: {},
    order_id: null,
    // заказ оформлен
    isPayed: false,
    // отработал ли локалстораж
    isCheckoutLocalStorageDone: false,
    card_bin: null,
    client_secret: '',
    redirect_url: '',
  },
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LIST_REQUEST:
      return {
        ...state,
        list: [],
        currentPage: action.payload,
        isLoading: true,
        isLoaded: false,
        error: '',
      };

    case types.FETCH_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.payload.orders,
        error: '',
      };

    case types.FETCH_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      };

    //

    case types.FETCH_ORDER_LIST_NEXT_PAGE_REQUEST:
      return {
        ...state,
        currentPage: action.payload,
        error: '',
      };

    case types.FETCH_ORDER_LIST_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.payload.orders],
        error: '',
      };

    case types.FETCH_ORDER_LIST_NEXT_PAGE_FAILURE:
      return {
        ...state,
        currentPage: 1,
        error: action.payload,
      };

    //

    case types.SET_ORDER_RATING_SUCCESS: {
      const { order_id, rate } = action.payload;
      const list = state.list.map(order =>
        order_id === order.id ? { ...order, rate: { ...order.rate, rate } } : order,
      );

      return {
        ...state,
        list,
        error: '',
      };
    }

    case types.SET_ORDER_RATING_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    //

    case types.SET_CHECKOUT_USER_DATA:
      return immutable.assign(state, ['checkout', 'user_data'], {
        ...action.payload,
      });

    case types.CLEAR_COMPANY_CHECKOUT_USER_DATA:
      return immutable.assign(state, ['checkout', 'user_data'], {
        company: '',
        department: '',
      });

    case types.SET_CHECKOUT_DELIVERY_TYPE: {
      return immutable.assign(state, 'checkout', {
        deliveryType: action.payload,
      });
    }

    case types.SET_CHECKOUT_BONUSES_TYPE:
      return immutable.assign(state, 'checkout', {
        bonusesType: action.payload,
      });

    case types.SET_CHECKOUT_DELIVERY_TIME_TYPE:
      return immutable.assign(state, 'checkout', {
        deliveryTimeType: action.payload,
      });

    case types.SET_CHECKOUT_DELIVERY_TIME:
      return immutable.assign(state, ['checkout', 'deliveryTime'], {
        ...action.payload,
      });

    case types.SET_CHECKOUT_PAY_TYPE:
      return immutable.assign(state, 'checkout', {
        payType: action.payload,
      });

    case types.SET_CHECKOUT_CHANGE:
      return immutable.assign(state, 'checkout', {
        change: action.payload,
      });

    case types.SET_CHECKOUT_IS_CARD_SAVED:
      return immutable.assign(state, 'checkout', {
        isCardSaved: action.payload,
      });

    case types.SET_CHECKOUT_CONTACT_FREE_DELIVERY:
      return immutable.assign(state, 'checkout', {
        isContactFreeDelivery: action.payload,
        previousIsContactFreeDelivery: state.checkout.isContactFreeDelivery,
      });

    case types.SET_CHECKOUT_DESCRIPTION:
      return immutable.assign(state, 'checkout', {
        description: action.payload,
      });

    case types.SET_CHECKOUT_IS_PAYED:
      return immutable.assign(state, 'checkout', {
        isPayed: true,
      });

    //

    case types.SET_CHECKOUT_DELIVERY_ADDRESS:
      return immutable.assign(state, 'checkout', {
        deliveryAddress: {
          ...state.checkout.deliveryAddress,
          ...action.payload,
        },
      });

    case types.SET_CHECKOUT_HOURS_MINUTES:
      return immutable.assign(state, 'checkout', {
        deliveryTime: { day: state.checkout.deliveryTime.day, hoursMinutes: action.payload },
      });

    case types.FETCH_DELIVERY_RESTAURANT_DETAILS_SUCCESS: {
      return immutable.assign(state, 'checkout', {
        deliveryRestaurant: action.payload,
        currentRestaurant: action.payload,
      });
    }

    case types.FETCH_RESTAURANT_DELIVERY_TIME_SUCCESS: {
      return immutable.assign(state, 'checkout', {
        deliveryRestaurant: {
          ...state.checkout.deliveryRestaurant,
          delivery_time: action.payload || state.checkout.deliveryRestaurant.delivery_time,
        },
      });
    }

    case types.SET_RESTAURANT_DETAILS_WITHOUT_TIME: {
      return immutable.assign(state, 'checkout', {
        currentRestaurant: action.payload,
      });
    }

    case types.SET_PICKUP_RESTAURANT_DETAILS: {
      return immutable.assign(state, 'checkout', {
        pickupRestaurant: action.payload,
        currentRestaurant: action.payload,
      });
    }

    case types.FETCH_RESTAURANT_DETAILS_WITH_TIME_SUCCESS: {
      return immutable.assign(state, 'checkout', {
        currentRestaurant: action.payload,
      });
    }

    case types.FETCH_RESTAURANT_INTERVALS_SUCCESS: {
      return immutable.assign(state, 'checkout', {
        intervals: action.payload,
      });
    }

    //

    case types.GET_LOCAL_STORAGE_CHECKOUT_FAILURE: {
      return immutable.assign(state, 'checkout', {
        isCheckoutLocalStorageDone: true,
      });
    }

    case types.GET_LOCAL_STORAGE_CHECKOUT_SUCCESS: {
      return immutable.assign(state, 'checkout', {
        ...action.payload,
        isCheckoutLocalStorageDone: true,
      });
    }

    case types.RESET_CHECKOUT: {
      return {
        ...state,
        checkout: initialState.checkout,
      };
    }

    case types.RESET_CHECKOUT_FIELD: {
      if (action.payload) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            [action.payload]: initialState.checkout[action.payload],
          },
        };
      }

      return { ...state, checkout: initialState.checkout };
    }

    case types.RESET_CHECKOUT_PAYMENT: {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order_data: initialState.checkout.order_data,
          order_id: initialState.checkout.order_id,
          isPayed: initialState.checkout.isPayed,
          client_secret: initialState.checkout.client_secret,
          redirect_url: initialState.checkout.redirect_url,
        },
      };
    }

    //

    case types.PAY_SUCCESS:
      return immutable.assign(state, 'checkout', {
        ...action.payload,
      });

    case types.RESET_MAX_SUM: {
      return {
        ...state,
        checkout: {
          ...state.checkout,
          currentRestaurant: {
            ...state.checkout.currentRestaurant,
            max_sum: 0,
          },
        },
      };
    }

    case types.SAVE_CARD_FOR_MIR: {
      const cardNumber = action.payload.replace(/\s/g, '').slice(0, 6);
      return {
        ...state,
        checkout: {
          ...state.checkout,
          card_bin: cardNumber,
        },
      };
    }

    case types.RESET_ORDER_STATE:
      return initialState;

    default:
      return state;
  }
}
