import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import PhoneInput from 'react-phone-input-2'
import de from 'react-phone-input-2/lang/de.json'

// Utils
import isomorphicCookies from "../../utils/cookie/isomorphicCookies";

// Config
import config from '../../config';

// Icons
// Styles
import styles from './PhoneFieldMultiCountry.styl';

const cx = classNames.bind(styles);

export const PhoneFieldStyleNamespace = {
  mobile: 'mobile',
  desktop: 'desktop',
  default: 'default'
}

export default function PhoneFieldMultiCountry(props) {
  const {
    className,
    name,
    value,
    disabled,
    required,
    autoFocus,
    label,
    description,
    children,
    _ref,
    //
    onChange,
    onFocus,
    onBlur,
    inputView,
    enableBuildInValidation = false
  } = props;


  const validatePhoneNumber = (
    inputNumber,
    country,
  ) => {
    const maskLength = country.format.match(/\./g)?.length || 0
    if (!inputNumber || !inputNumber.startsWith(country.dialCode) || inputNumber.length < maskLength) {
      return false
    } else {
      return true
    }
  }
  const lang = isomorphicCookies().get('locale');
  const [currCountry, setCurrCountry] = useState('')
  const handleOnChange = (value, countryProps, e, formattedValue) => {
    if (countryProps === countryProps.dialCode) {
      setCurrCountry(countryProps.dialCode);
    }
    // Enforce a 12-character limit
    const limitedValue = value.slice(0, 12);

    const isValid = enableBuildInValidation ? (limitedValue.length > 8 && limitedValue.length <= 12) : true;

    onChange(e, isValid, countryProps.countryCode);
  };

  const handleOnFocus = e => {
    onFocus(e);
  };


  const handleOnBlur = e => {
    onBlur(e);
  };


  return (
    <div
      className={cx('PhoneFieldMultiCountry', className, {
        PhoneField_disabled: disabled,
      })}
    >
      {Boolean(label) && (
        <label className={cx('PhoneFieldMultiCountry__label', {
          PhoneFieldMultiCountry__label_mobile: inputView === PhoneFieldStyleNamespace.mobile,
          PhoneFieldMultiCountry__label_desktop: inputView === PhoneFieldStyleNamespace.desktop,
        })} htmlFor={name}>
          {label} {required && '*'}
        </label>
      )}
      <div className={cx('PhoneFieldMultiCountry__wrapper')}>
        <PhoneInput
          // onlyCountries={['de', 'fr', 'pl', 'by']}
          localization={lang === 'de' ? de : ''}
          regions={['north-america', 'europe']}
          placeholder="Enter phone number"
          preferredCountries={['de', 'fr', 'pl', 'be', 'ca', 'us']}
          preserveOrder={["preferredCountries"]}
          value={value || currCountry}
          onChange={handleOnChange}
          country={config.legal}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          inputStyle={children ? {paddingLeft: '16px'} : {}}
          // masks={{de: config.phone.format}}
          inputClass={cx('PhoneFieldMultiCountry__input', {
            PhoneFieldMultiCountry__input_mobile: inputView === PhoneFieldStyleNamespace.mobile,
            PhoneFieldMultiCountry__input_desktop: inputView === PhoneFieldStyleNamespace.desktop,
          })}
          dropdownClass={cx('PhoneFieldMultiCountry__dropdownContainer')}
          containerClass={cx('PhoneFieldMultiCountry')}
          buttonClass={cx('PhoneFieldMultiCountry__button', {'PhoneFieldMultiCountry__button_disabled': disabled})}
          searchClass={'searchClass'}
        />
        {children}
      </div>
      {description && <div className={cx('PhoneFieldMultiCountry__description')}>{description}</div>}
    </div>
  );
}

PhoneFieldMultiCountry.defaultProps = {
  className: '',
  name: '',
  value: '',
  disabled: false,
  required: false,
  autoFocus: false,
  description: '',
  children: null,
  _ref: null,
  //
  onChange: () => {
  },
  onFocus: () => {
  },
  onBlur: () => {
  },
  inputView: 'default',
};

PhoneFieldMultiCountry.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.any,
  _ref: PropTypes.object,
  //
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputView: PropTypes.oneOf(Object.keys(PhoneFieldStyleNamespace)),
  enableBuildInValidation: PropTypes.bool
};

