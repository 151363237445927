import * as types from './actionTypes';
import restaurantApi from './api';

export const fetchRestaurants = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_LIST_REQUEST });

    return restaurantApi
      .getRestaurants()
      .then(data => {
        return dispatch({ type: types.FETCH_LIST_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_LIST_FAILURE, payload: message });
      });
  };
};

export function fetchStopList(restaurant_id) {
  return dispatch => {
    dispatch({ type: types.FETCH_RESTAURANT_STOPLIST_REQUEST });

    return restaurantApi
      .getCartStopList(restaurant_id)
      .then(data => {
        return dispatch({ type: types.FETCH_RESTAURANT_STOPLIST_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_RESTAURANT_STOPLIST_FAILURE, payload: message });
      });
  };
}

export const clearRestaurantStopList = () => ({
  type: types.CLEAR_RESTAURANT_STOPLIST,
});
