import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import InputField from '../../InputField/DesktopView';
import ToggleArrow from '../../ToggleArrow';
import Collapse from '../../Collapse';
import Button from '../../Button';
import Heading from '../../Heading/DesktopView';
import Map from '../../AddressFieldGroup/partials/Map';
import AddressesField from '../../AddressFieldGroup/partials/AddressesField';
import CitySelector from '../../CitySelector';
import useAddressSelector from '../useAddressSelector';

// State
import { mapLocaleSelector } from '../../../state/modules/intl/selectors';

// Utils
import { formatSavedAddress } from '../../../utils/formatters';

// Config
import config from '../../../config';

// Styles
import styles from './AddressSelector.styl';

const cx = classNames.bind(styles);

export default function AddressSelector(props) {
  const { className, onSuccessCallback, modalMode = false } = props;

  const intl = useIntl();
  const mapLocale = useSelector(mapLocaleSelector);
  const list = useSelector(state => state.address.list);
  const userCity = useSelector(state => state.city.userCity);

  const {
    buttonRef,
    address,
    isLoading,
    addressInLineValue,
    isIntercomAndPorchOpened,
    setAddressInLineValue,
    //
    handleIntercomAndPorchToggle,
    handleAddressOnChange,
    handleOnBlur,
    handleOnAddressInLineSelect,
    handleSelectSavedAddress,
    //
    isButtonExist,
    handleOnAccept,
    isError,
  } = useAddressSelector(onSuccessCallback);

  const label = isError
    ? intl.formatMessage({ id: 'field.address.error' })
    : intl.formatMessage({ id: 'field.address.label' });

  const cityCenter =
    (config.legal === 'pl' || config.legal === 'de')
      ? [...userCity.coordinates]
      : [userCity.coordinates[1], userCity.coordinates[0]];

  return (
    <form className={cx('AddressSelector', className)} autoComplete="off">
      <div className={cx('AddressSelector__header')}>
        <Heading className={cx('AddressSelector__title')} level={3}>
          {intl.formatMessage({ id: 'select.address' })}
        </Heading>
        <CitySelector />
      </div>
      <Map
        className={cx('AddressSelector__map')}
        mapAddressOnChange={handleOnAddressInLineSelect}
        selectedAddress={address.address_in_line}
        center={cityCenter}
        mapLocale={mapLocale}
        userCity={userCity}
      />
      <div className={cx('AddressSelector__addresses')}>
        {list.map((addr, i) => (
          <button
            key={addr.id}
            className={cx('AddressSelector__address', {
              AddressSelector__address_active: addr.id === address.address_id,
            })}
            onClick={() => handleSelectSavedAddress(addr)}
            type="button"
          >
            {`${formatSavedAddress(addr)}${i === list.length - 1 ? '' : ','}`}
          </button>
        ))}
      </div>
      <div className={cx('AddressSelector__addresses')}>
        {label}
      </div>
      <div className={cx('AddressSelector__first')}>
        {/* Адрес */}
        <AddressesField
          className={cx('AddressSelector__addresser', {
            AddressSelector__adresser_mode: modalMode,
          })}
          onAddressSelect={handleOnAddressInLineSelect}
          newest
          input={{
            name: 'address_in_line',
            value: addressInLineValue,
            onChange: value => setAddressInLineValue(value),
            required: true,
            placeholder: intl.formatMessage({ id: 'field.postalCode.label' }),
            isError,
          }}
          textBlockInput={true}
        />
        {/* Квартира / офис */}
        {/* Этаж */}
        {!modalMode && (
          <>
            <InputField
              className={cx('AddressSelector__flat')}
              name="flat"
              value={address.flat}
              onChange={handleAddressOnChange}
              placeholder={intl.formatMessage({ id: 'field.flat.label' })}
              onBlur={handleOnBlur}
            />
            <InputField
              className={cx('AddressSelector__floor')}
              name="floor"
              value={address.floor}
              onChange={handleAddressOnChange}
              placeholder={intl.formatMessage({ id: 'field.floor.label' })}
              onBlur={handleOnBlur}
            />
          </>
        )
        }
      </div>
      {/* Указать домофон и подъезд */}
      {!modalMode && <div
        className={cx('AddressSelector__toggle')}
        onClick={handleIntercomAndPorchToggle}
        onKeyDown={e => e.key === 'Enter' && handleIntercomAndPorchToggle()}
        role="button"
        tabIndex={0}
      >
        {intl.formatMessage({ id: 'button.addIntercom' })}
        <ToggleArrow className={cx('AddressSelector__arrow')} isOpen={isIntercomAndPorchOpened} />
      </div>}
      <Collapse isOpen={isIntercomAndPorchOpened}>
        <div className={cx('AddressSelector__second')}>
          {/* Домофон */}
          <InputField
            className={cx('AddressSelector__intercom')}
            name="intercom"
            value={address.intercom}
            onChange={handleAddressOnChange}
            placeholder={intl.formatMessage({ id: 'field.intercom.label' })}
            onBlur={handleOnBlur}
          />
          {/* Подъезд */}
          <InputField
            className={cx('AddressSelector__porch')}
            name="porch"
            value={address.porch}
            onChange={handleAddressOnChange}
            placeholder={intl.formatMessage({ id: 'field.porch.label' })}
            onBlur={handleOnBlur}
          />
        </div>
      </Collapse>
      {isButtonExist && (
        <Button
          buttonRef={buttonRef}
          className={cx('AddressSelector__accept')}
          onClick={handleOnAccept}
          disabled={!address.address_in_line}
          isLoading={isLoading}
        >
          {intl.formatMessage({ id: 'button.accept' })}
        </Button>
      )}
    </form>
  );
}

AddressSelector.defaultProps = {
  className: '',
  onSuccessCallback: undefined,
};

AddressSelector.propTypes = {
  className: PropTypes.string,
  onSuccessCallback: PropTypes.func,
};
