import { createSelector } from 'reselect';
import _take from 'lodash/take';

// State
import { getIsAllConditionsCheckedSelector } from '../stock/selectors';
import { bonusesInCompositionSelector } from '../cart/selectors';

// Utils
import { isCombobox, isPromotional, isUpsale } from '../../../utils/cart';

// Config
import config from '../../../config';

// берем два последних заказа для истории заказов на главной
export const twoLastOrdersSelector = createSelector(
  state => state.order.list,
  list => _take(list, 2),
);

// вычисляем стоимость заказа со скидками
export const priceIncludingBonusesSelector = createSelector(
  [
    state => state.order.checkout.deliveryType,
    state => state.cart.sailplay_discount,
    state => state.stock.data.code,
    state => state.cart.discount_price,
    state => state.stock.unconditionalList.data.rewards?.[0] || {},
    state => state.cart.composition,
    bonusesInCompositionSelector,
    state => state.order.checkout.currentRestaurant?.has_takeaway_discount,
  ],
  (
    deliveryType,
    sailplay_discount,
    stockCode,
    discount_price,
    pickupBonuses,
    composition,
    bonusesInComposition,
    has_takeaway_discount,
  ) => {
    let currentPrice = discount_price;
    // если включен самовывоз и не активированы акции
    if (
      deliveryType === 'pickup' &&
      pickupBonuses.reward === 'order_discount' &&
      !stockCode &&
      !sailplay_discount &&
      !bonusesInComposition.length && has_takeaway_discount
    ) {
      // то вычитаем из общей суммы процент за самовывоз
      if (pickupBonuses.type === 'percent') {
        const promotionalsPrices = [];

        // вычитаем из всех товаров, кроме акционных - на них скидка не действует
        //вычитаем так же из товаров из спец категорий
        composition.forEach(item => {
          if (
            isPromotional(item) ||
            item?.is_excluded_from_min_amount ||
            isCombobox(item) ||
            (isUpsale(item) && item.upsale_status !== false)
          ) {
            const itemSum = item.discount_price * item.count;
            promotionalsPrices.push(itemSum);
            currentPrice -= itemSum;
          }
        });

        currentPrice -= (currentPrice / 100) * pickupBonuses.reward_value;

        promotionalsPrices.forEach(promotionalPrice => {
          currentPrice += promotionalPrice;
        });
      } else {
        currentPrice = Math.max(0, discount_price - pickupBonuses.reward_value);
      }
    }

    // просто отнимаем из общей суммы использованные бонусы
    currentPrice -= sailplay_discount;

    const result =
      config.legal === 'by' || config.legal === 'pl' || config.legal === 'de'
        ? Number(currentPrice)
        : Math.floor(Number(currentPrice));

    return result <= 0 ? 0 : result;
  },
);

// вычисляем минимальную сумму заказа
export const minOrderSumSelector = createSelector(
  [
    state => state.order.checkout.currentRestaurant?.min_sum,
    state => state.stock.data.details?.min_order_sum,
    state => state.stock.isApplied,
    getIsAllConditionsCheckedSelector,
  ],
  (minOrderSum, minStockSum, stockIsApplied, stockIsChecked) => {
    let minSum = minOrderSum || 0;

    if (stockIsApplied && stockIsChecked && (minStockSum === 0 || minStockSum === 1)) {
      minSum = minStockSum;
    }

    return minSum;
  },
);

// вычисляем стоимость доставки
export const totalDeliveryCostSelector = createSelector(
  [
    priceIncludingBonusesSelector,
    state => state.order.checkout.currentRestaurant?.delivery_cost,
    state => state.order.checkout.currentRestaurant?.max_sum,
    minOrderSumSelector,
  ],
  (priceIncludingBonuses, delivery_cost = 0, max_sum = 0, minSum) => {
    return priceIncludingBonuses !== 0 &&
    delivery_cost > 0 &&
    priceIncludingBonuses >= minSum &&
    priceIncludingBonuses <= max_sum
      ? delivery_cost
      : 0;
  },
);

// перешагнули ли мы порог минимальной доставки
export const isOrderSumLowSelector = createSelector(
  [
    priceIncludingBonusesSelector,
    minOrderSumSelector,
    state => state.order.checkout.deliveryType,
    state => state.cart.sailplay_discount,
    state => state.cart.discount_price,
    state => state.cart.composition,
    state => state.cart.stock_code,
  ],
  (priceIncludingBonuses, minSum, deliveryType, sailplay_discount, discount_price, composition, stock_code) => {
    // if (deliveryType === 'delivery' && discount_price > 0 && stock_code) {
    //   return false;
    // }
    const costOfInceptions = composition?.reduce((accum, good) => {
      if (good?.is_excluded_from_min_amount) {
        return accum + (good.count * good.price);
      }
      return accum + 0;
    }, 0);
    const totalDiscount = costOfInceptions > sailplay_discount ? costOfInceptions : sailplay_discount;
    const totalCountOfComposition = priceIncludingBonuses + sailplay_discount;
    if (deliveryType === 'delivery') {
      return totalCountOfComposition - totalDiscount < minSum;
    }

    return false;
  },
);

// это ненастоящий селектор
export const isOrderDoneSelector = orderData => {
  if (!orderData) {
    return false;
  }

  return Number(orderData.order_status) === 6 || Number(orderData.order_status) === 5;
};

// максимальное кол-во бонусов которое мы можем использовать для кешбека
export const maxCashbackCanUseSelector = createSelector(
  [
    state => state.cart.discount_price,
    state => state.city.userCity.sailplay?.discount_percent || 100,
    state => state.city.userCity.sailplay?.max_points_spend,
    minOrderSumSelector,
  ],
  (discount_price, discount_percent, max_points_spend, min_sum = 0) => {
    const bonusesEdge = Math.floor((discount_price * discount_percent) / 100);
    const min = Math.min(
      bonusesEdge,
      discount_price - min_sum <= 0 ? 0 : Math.floor(discount_price - min_sum),
    );

    return max_points_spend !== undefined ? Math.min(min, max_points_spend) : min;
  },
);

// вычисляем зону доставки для того, чтобы прокинуть в методы, связанные с коризиной
export const getDeliveryAreaIdSelector = createSelector(
  [state => state.order.checkout.currentRestaurant, state => state.order.checkout.deliveryType],
  (currentRestaurant, deliveryType) => {
    return deliveryType === 'delivery' && currentRestaurant
      ? currentRestaurant.delivery_area_id
      : undefined;
  },
);
