import isomorphicCookies from './isomorphicCookies';
import config from '../../config';

// чтобы кука ушла в запросе на staging-api.papajohns.ru или api.papajohns.ru, нужен same-domain.
const withDomain = expires => ({
  expires,
  domain: `.papajohns.${config.legal}`,
});

export default function setCookiesFromGetParams() {
  const params_array = window.location.search.substring(1).split('&');
  const params_result = {};

  for (let i = 0; i < params_array.length; i += 1) {
    const params_current = params_array[i].split('=');
    params_result[params_current[0]] =
      typeof params_current[1] === 'undefined' ? '' : params_current[1];
  }

  if (params_result.utm_source !== undefined && params_result.utm_source !=='admitad') {
    const expires = 14;
    isomorphicCookies().remove('admitad_uid')
    isomorphicCookies().set('utm_source', params_result.utm_source, withDomain(expires));
    isomorphicCookies().set('utm_medium', params_result.utm_medium, { expires });
    isomorphicCookies().set('utm_campaign', params_result.utm_campaign, { expires });
  }

  if (params_result.utm_source === 'advertise') {
    const expires = 30;

    isomorphicCookies().set('adv_uid', params_result.uid, withDomain(expires));
    isomorphicCookies().set('utm_source', params_result.utm_source, withDomain(expires));
    isomorphicCookies().set('utm_medium', params_result.utm_medium, { expires });
    isomorphicCookies().set('utm_campaign', params_result.utm_campaign, { expires });
  }

  if (params_result.utm_source === 'actionpay') {
    const expires = 14;

    isomorphicCookies().set('actionpay', params_result.actionpay, withDomain(expires));
    isomorphicCookies().set('utm_source', params_result.utm_source, withDomain(expires));
    isomorphicCookies().set('utm_medium', params_result.utm_medium, { expires });
    isomorphicCookies().set('utm_campaign', params_result.utm_campaign, { expires });
    isomorphicCookies().set('last_paid_source', params_result.utm_source, { expires });
    isomorphicCookies().set('last_paid_medium', params_result.utm_medium, { expires });
    isomorphicCookies().set('last_paid_campaign', params_result.utm_campaign, { expires });
  }

  if (params_result.utm_source === 'cityads') {
    const expires = 14;

    isomorphicCookies().set('click_id', params_result.click_id, withDomain(expires));
    isomorphicCookies().set('utm_source', params_result.utm_source, withDomain(expires));
    isomorphicCookies().set('utm_medium', params_result.utm_medium, { expires });
    isomorphicCookies().set('utm_campaign', params_result.utm_campaign, { expires });
  }

  if (params_result.utm_source === 'admitad') {
    const expires = 14;

    isomorphicCookies().set('admitad_uid', params_result.admitad_uid, withDomain(expires));
    isomorphicCookies().set('utm_source', params_result.utm_source, withDomain(expires));
    isomorphicCookies().set('utm_medium', params_result.utm_medium, { expires });
    isomorphicCookies().set('utm_campaign', params_result.utm_campaign, { expires });
  }
}
