import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import classNames from 'classnames/bind';
import DeviceDetector from 'device-detector-js';

// Components
import InputField from '../../../InputFieldAdress';

// Utils
import useOutsideClick from '../../../../utils/useOutsideClick';

// Config
import config from '../../../../config';

// Styles
import styles from './SuggestionsPapajohns.styl';

const cx = classNames.bind(styles);

export default function SuggestionsPapajohns(props) {
  const { input, className, onAddressSelect, onBlur, newest, label, inputClassName, _ref } = props;

  const id = useSelector(state => state.city.userCity.id);
  const locale = useSelector(state => state.intl.locale);

  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const handleFetchByQuery = async searchQuery => {
    try {
      if (searchQuery.length < 3) {
        setSuggestions([]);
        return;
      }

      const response = await fetch(
        `${config.geoUrl}/suggestions?q=${searchQuery}&city_id=${id}&lang=${locale}`,
        {
          method: 'GET',
          headers: { Accept: 'application/json' },
        },
      );

      const data = await response.json();

      setSuggestions(data);

      if (!response.ok) {
        throw new Error(data.message || 'Что-то пошло не так');
      }
    } catch (e) {
      console.warn(e);
      throw e;
    }
  };

  const debouncedHandleFetchByQuery = useCallback(
    _debounce(q => handleFetchByQuery(q), 300),
    [id],
  );

  const handleOnChange = e => {
    input.onChange(e.target.value);
    debouncedHandleFetchByQuery(e.target.value);
  };

  const handleOnFocus = e => {
    setIsFocused(true);
    handleFetchByQuery(input.value);

    if (input.onFocus) {
      input.onFocus(e);
    }
  };

  const handleOnSuggestClose = () => {
    setIsFocused(false);
  };

  const handleOnSuggestClick = address => {
    input.onChange(address);
    onAddressSelect({ value: address });
    handleOnSuggestClose();
  };

  const handleOnKeyDown = e => {
    // если открыта поисковая выдача, включаем управление на стрелки
    if (isFocused) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        return setCurrentIndex(prev => (prev === 0 ? 0 : prev - 1));
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        return setCurrentIndex(prev =>
          prev === suggestions.length - 1 ? suggestions.length - 1 : prev + 1,
        );
      }

      // при нажатии на энтер подставляем поисковую выдачу. если в поиске ничего нет, то закрываем поиск
      if (e.key === 'Enter' && suggestions.length > 0) {
        e.preventDefault();
        e.target.blur();

        if (suggestions[currentIndex]) {
          return handleOnSuggestClick(suggestions[currentIndex].address);
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (input.value) {
      handleFetchByQuery(input.value);
    }
  }, []);

  // каждый раз при открытии предложки ставим указатель на -1 элемент (невыбранный)
  useEffect(() => {
    setCurrentIndex(-1);
  }, [isFocused]);

  // если указатель стоит на несуществующей позиции, то перекинуть на существующую
  useEffect(() => {
    if (currentIndex > suggestions.length - 1) {
      setCurrentIndex(suggestions.length - 1);
    }
  }, [suggestions]);

  const nodeRef = useOutsideClick(handleOnSuggestClose, isFocused);

  return (
    <div className={cx('SuggestionsPapajohns', className)} ref={nodeRef}>
      <InputField
        {...input}
        className={cx('SuggestionsPapajohns__input')}
        inputClassname={inputClassName}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={onBlur}
        onKeyDown={handleOnKeyDown}
        newest={newest}
        label={label}
        autoComplete="off"
        _ref={_ref}
      />
      {isFocused && (
        <ul className={cx('SuggestionsPapajohns__suggestions')}>
          {suggestions.map((suggestion, i) => (
            <li
              key={suggestion.address}
              className={cx('SuggestionsPapajohns__suggestion', {
                SuggestionsPapajohns__suggestion_focused: i === currentIndex,
              })}
              onClick={handleOnSuggestClick.bind(this, suggestion.address)}
              tabIndex={0}
              role="button"
              onKeyDown={() => null}
            >
              {suggestion.address}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

SuggestionsPapajohns.defaultProps = {
  className: '',
  inputClassName: '',
  newest: false,
  label: '',
};

SuggestionsPapajohns.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onAddressSelect: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  newest: PropTypes.bool,
  label: PropTypes.string,
};
