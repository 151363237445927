import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";
import { useIntl } from "react-intl";
import { Link, Route } from "react-router-dom";

// Components
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import Button from "../../components/Button";

// Assets
import cover from "./assets/404.png";
import ny_404 from "../../assets/NY_images/NY_404.png";

// Router
import { getRoute, paths } from "../../entry/routes";

// Styles
import styles from "./Error404.styl";

const cx = classNames.bind(styles);

export default function Error404Page() {
  const intl = useIntl();
  const dispatch = useDispatch();

  // const catalogSliderNotFound = useSelector(state => state.catalog.sliderNotFound);
  const isNewYearMode = useSelector(state => !!state.city.userCity.is_new_year_mode_on);

  const [screenWidth, setScreenWidth] = useState();

  // const onAddToCart = async id => {
  //   await dispatch(addCartItem({ composition: [{ type: "good", item: { id } }] }));
  // };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    // dispatch(fetchCatalogNotFoundSliderRequest());
  }, []);

  useEffect(() => {
    const setWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  });

  return (
    <Route>
      {({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = 404;
        }

        return (
          <>
            <Helmet title={intl.formatMessage({ id: "page.error404.helmetTitle" })} />
            <Container className={cx("Error404", {
              "Error404__new-year": isNewYearMode
            })}>
              <div className={cx("Error404__header")} />
              <div className={cx("Error404__wrapper")}>
                <div className={cx("Error404__content")}>
                  {screenWidth <= 1024 && (
                    <Link to={getRoute(paths.home)}>
                      <button className={cx("Error404__button-back")} />
                    </Link>
                  )}
                  <Heading className={cx("Error404__title")} level={1}>
                    {intl.formatMessage({ id: "page.error404.text" })}
                  </Heading>
                  <img className={cx("Error404__image")} src={isNewYearMode ? ny_404 : cover} alt="error" />

                  {screenWidth <= 1024 && (
                    <p className={cx("Error404__text")}>
                      {intl.formatMessage({ id: "page.error404.mobile.text" })}
                      <br /> <br />
                      {intl.formatMessage({ id: "page.error404.mobile.text2" })}
                    </p>
                  )}

                  <Button className={cx("Error404__button")} to={getRoute(paths.home)}>
                    {intl.formatMessage({ id: "button.goToMenu.goToMain" })}
                  </Button>
                </div>
              </div>
            </Container>

            <div className={cx("Error404__footer")}>
              {/*<div className={cx("Error404__footer-wrapper")}>*/}
              {/*  {screenWidth > 1024 && (*/}
              {/*    <p className={cx("Error404__footer-title")}>{intl.formatMessage({ id: "error.tryNew" })}</p>*/}
              {/*  )}*/}
              {/*  <div className={cx("Error404__footer-slider-block")}>*/}
              {/*    {screenWidth > 1024 ? (*/}
              {/*      <GiftsSliderFourZeroFour*/}
              {/*        screenWidth={screenWidth}*/}
              {/*        catalogSliderNotFound={catalogSliderNotFound}*/}
              {/*        onAddToCart={onAddToCart}*/}
              {/*      />*/}
              {/*    ) : (*/}
              {/*      catalogSliderNotFound?.map((gift, index) => (*/}
              {/*        <GiftSlide*/}
              {/*          key={index}*/}
              {/*          gift={gift}*/}
              {/*          onAddToCart={onAddToCart}*/}
              {/*          withoutButton={false}*/}
              {/*        />*/}
              {/*      ))*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </>
        );
      }}

    </Route>
  );
}
