import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Form from '../../Form';
import InputField from '../../InputField';
import PasswordField from '../../PasswordField';
import Button from '../../Button';
import FieldError from '../../FieldError';
import SuccessMessage from '../../SuccessMessage';

// State
//Actions
import * as userActions from '../../../state/modules/user/actions';
import {
  recoveryPasswordMail,
  recoveryPasswordPhone,
  recoveryPasswordReset,
} from '../../../state/modules/user/actions';

// Utils
import { composeValidators, passwordIsEqual, required, strongPassword } from '../../../utils/validators';

// Styles
import styles from './RecoveryPasswordSecondStepForm.styl';

const cx = classNames.bind(styles);

export default function RecoveryPasswordSecondStepForm(props) {
  const { className, goToSignIn, onCancel, fromModal } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const [count, setCount] = useState(60);
  const [sms, setSms] = useState(false);

  useEffect(() => {
    if (!count) return;
    const intervalId = setInterval(() => {
      setCount(count - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [count]);

  const { isRecovered, phone, email, error, isRecovering } = useSelector(
    state => state.user.recoveryPassword,
  );

  const handleToggleSms = () => {
    if (count === 0) {
      setCount(60);
      setSms(true);
      dispatch(userActions.recoveryPasswordSendConfirmCode({ phone, type: 'recovery_password', channel: 'sms' }));
    }
  };

  const submit = values => {
    phone ? dispatch(recoveryPasswordPhone({ phone, ...values }))
      : dispatch(recoveryPasswordMail({ email, ...values }));
  };

  const repeatFlashCall = () => {
    setCount(60);
    dispatch(userActions.recoveryPasswordSendConfirmCode({
      phone,
      type: 'recovery_password',
      channel: sms ? 'sms' : 'flash_call',
    }));
  };

  useEffect(() => {
    return () => {
      if (isRecovered) {
        dispatch(recoveryPasswordReset());
      }
    };
  }, [isRecovered]);

  if (isRecovered) {
    return (
      <div className={cx('RecoveryPasswordSecondStepForm')}>
        <FormattedMessage id="recoveryPassword.successMessage.text">
          {msg => (
            <SuccessMessage text={msg} className={className}>
              {!fromModal && <Button onClick={goToSignIn}>
                <FormattedMessage id="button.signIn" />
              </Button>}
            </SuccessMessage>
          )}
        </FormattedMessage>
      </div>
    );
  }

  return (
    <div className={cx('RecoveryPasswordSecondStepForm')}>
      <div className={cx('RecoveryPasswordSecondStepForm__note')}>
        {phone ? (
          <FormattedMessage id={'recoveryPassword.phone3'} />
        ) : (
          <div className={className}>
            <FormattedMessage id="recoveryPassword.email" values={{ email }} />
          </div>

        )}
      </div>
      <FinalForm
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field name="password" validate={composeValidators(required, strongPassword)}>
              {({ input }) => (
                <Form.Field>
                  <FormattedMessage id="field.newPassword.label">
                    {label => <PasswordField {...input} label={label} />}
                  </FormattedMessage>
                  <FieldError name="password" />
                </Form.Field>
              )}
            </Field>
            <Field
              name="password_repeat"
              validate={composeValidators(required, passwordIsEqual('password'))}
            >
              {({ meta, input }) => (
                <Form.Field>
                  <FormattedMessage id="field.repeatPassword.label">
                    {label => <PasswordField {...input} label={label} />}
                  </FormattedMessage>
                  <FieldError name="password_repeat" />
                </Form.Field>
              )}
            </Field>
            <Field name="code" validate={required}>
              {({ input }) => (
                <Form.Field>
                  <InputField
                    {...input}
                    label={
                      phone ? (
                        <FormattedMessage id="field.smsCode.label2">
                          {label => label}
                        </FormattedMessage>
                      ) : (
                        <FormattedMessage id="field.code.label">{label => label}</FormattedMessage>
                      )
                    }
                  />
                  <FieldError name="code" />
                  <Form.Error error={error} />
                </Form.Field>
              )}
            </Field>
            {/*{phone && <div className={cx('RecoveryPasswordSecondStepForm__repeat')}>*/}
            {/*  {count > 0 && <div>*/}
            {/*    {intl.formatMessage({ id: 'button.sendSmsCodeAgain2' }, { count })}*/}
            {/*  </div>*/}
            {/*  }*/}
            {/*  {!count && <div className={cx('RecoveryPasswordSecondStepForm__repeat_again')}*/}
            {/*                  onClick={repeatFlashCall}*/}
            {/*  >*/}
            {/*    {intl.formatMessage({ id: 'button.sendSmsCodeAgain1' })}*/}
            {/*  </div>*/}
            {/*  }*/}
            {/*</div>}*/}
            {/*{phone && !sms && <div*/}
            {/*  onClick={handleToggleSms}*/}
            {/*  className={cx('RecoveryPasswordSecondStepForm__notCall', {'RecoveryPasswordSecondStepForm__notCall_condition': count===0})}>*/}
            {/*  <div>*/}
            {/*    {intl.formatMessage({ id: 'recoveryPassword.phone4' })}*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}
            <div
              className={cx('RecoveryPasswordSecondStepForm__actions')}
            >
              <Button
                className={cx('RecoveryPasswordSecondStepForm__actions__item')}
                onClick={onCancel}
                link
              >
                <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                className={cx('RecoveryPasswordSecondStepForm__actions__item')}
                type="submit"
                isLoading={isRecovering}
              >
                <FormattedMessage id="button.recoveryPassword" />
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  );
}

RecoveryPasswordSecondStepForm.defaultProps = {
  className: '',
};

RecoveryPasswordSecondStepForm.propTypes = {
  className: PropTypes.string,
  goToSignIn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fromModal: PropTypes.bool,
};
