import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './Heading.styl';

const cx = classNames.bind(styles);

export default function Heading(props) {
  const { className, children, level, tagName, medium, ...otherProps } = props;

  const TagName = tagName || `h${level}`;

  return (
    <TagName
      {...otherProps}
      className={cx('Heading', className, `Heading_level_${level}`, { Heading_medium: medium })}
    >
      {children}
    </TagName>
  );
}

Heading.defaultProps = {
  className: '',
  level: 2,
  tagName: '',
  medium: false,
};

Heading.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  level: PropTypes.number,
  tagName: PropTypes.any,
  medium: PropTypes.bool,
};
