// из этой библиотеки можно взять маски для конфига. ее саму устанавливать не нужно
// import countryTelData from 'country-telephone-data';

import config from '../config';

/**
 * Форматирует +7 (999) 99 99 999 ---> +79999999999
 * @param  {String} value
 * @return {String}
 */
export const plainPhoneNumber = value => {
  if (!value) {
    return value;
  }

  return value.replace(/[^0-9\\+]/g, '');
};

/**
 * Возращает маску телефона, предварительно определяя страну
 * Если формат номера не точный то возращает первые совпадения
 * @param  {String} value
 * @return {String}
 */
export const maskedPhoneNumber = (telephone='', mask) => {

  if (!telephone || typeof telephone === "number") {
    return undefined;
  }

  // приводим к 79999999999 (без плюса)
  const clearedTelephone = telephone.replace(/[^0-9]+/g, '');
  // берем маску страны из конфига
  const phoneFormat = mask || config.phone.format;

  let symbolNum = 0;
  let newTelephoneMask = '';

  for (let i = 0; i < phoneFormat.length; i += 1) {
    if (phoneFormat[i] === '.') {
      if (clearedTelephone[symbolNum] === undefined) {
        break;
      }

      newTelephoneMask += clearedTelephone[symbolNum];
      symbolNum += 1;
    } else {
      newTelephoneMask += phoneFormat[i];
    }
  }

  return newTelephoneMask;
};
