import React, { Component } from 'react';
import { Map, YMaps, ZoomControl } from 'react-yandex-maps';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import { MAP } from 'react-google-maps/lib/constants';

// Components
import Preloader from '../../../Preloader';
import { GMaps } from '../../../GoogleMap';

// Assets
import locationIcon from '../../assets/location.svg';

// Config
import config from '../../../../config';

// Styles
import styles from './Map.styl';

const cx = classNames.bind(styles);

export default class AddressMap extends Component {
  constructor(props) {
    super(props);
    this.address = props.selectedAddress;
  }

  state = { isLoaded: false, currentCenter: [] };

  componentDidUpdate(prevProps) {
    const { isLoaded } = this.state;
    const { selectedAddress } = this.props;

    if (prevProps.selectedAddress !== selectedAddress && selectedAddress !== this.address) {
      if (isLoaded) {
        if (config.legal === 'de') {
          // пока убрал, потому что получается луп при выборе адреса из предложки
          // this.setGoogleMarker(true, selectedAddress);
        } else {
          this.geocodePlacemark(selectedAddress);
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.userMarker) {
      this.userMarker.setMap(null);
    }

    if (this.mapContext) {
      google.maps.event.clearListeners(this.mapContext, 'click');
    }
  }

  // google

  getMapRef = ref => {
    if (ref) {
      this.mapContext = ref.context[MAP];
      this.setState({ isLoaded: true });

      if (this.mapContext) {
        this.geocoder = new google.maps.Geocoder();

        this.mapContext.addListener('click', e => {
          this.setGoogleMarker(false, new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()));
          this.mapContext.setZoom(15);
        });
      }
    }
  };

  setGoogleMarker = (isAddress, data) => {
    const { mapAddressOnChange } = this.props;

    if (data && this.geocoder) {
      const request = isAddress ? { address: data } : { location: data };

      if (this.userMarker) {
        this.userMarker.setMap(null);
      }

      this.geocoder.geocode(request, (results, status) => {
        if (status === 'OK') {
          // this.mapContext.setCenter(results[0].geometry.location);
          const firstResult = results[0];

          if (firstResult) {
            this.userMarker = new google.maps.Marker({
              map: this.mapContext,
              icon: locationIcon,
              position: results[0].geometry.location,
            });

            this.address = firstResult.formatted_address;

            if (this.address) {
              mapAddressOnChange(this.address);
            }
          }
        } else {
          console.error(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    }
  };

  // yandex

  instanceRef = ref => {
    this.mapRef = ref;
  };

  setMapCenter = (coordinates, zoom = false) => {
    this.mapRef.setCenter(coordinates);
    this.setState({ currentCenter: coordinates });

    if (zoom) {
      this.mapRef.setZoom(zoom);
    }
  };

  setPlacemark = coords => {
    this.searchPlacemark.geometry.setCoordinates(coords);
    this.searchPlacemark.options.set('visible', true);
  };

  setAddressFromGeoObject = geoObject => {
    const { mapAddressOnChange } = this.props;

    this.address = geoObject.getAddressLine();

    if (this.address) {
      mapAddressOnChange(this.address);
    }
  };

  handleApiAvaliable = selectedYmaps => {
    const { selectedAddress } = this.props;

    this.ymaps = selectedYmaps;

    this.ymaps.load(() => {
      this.mapRef.cursors.push('arrow');

      this.setState({ isLoaded: true });
      this.searchPlacemark = new this.ymaps.Placemark(
        [0, 0],
        {},
        {
          iconLayout: 'default#image',
          iconImageHref: locationIcon,
          iconImageSize: [26, 26],
          iconImageOffset: [-13, -26],
          cursor: 'default',
          visible: false,
        },
      );

      this.mapRef.geoObjects.add(this.searchPlacemark);

      if (selectedAddress) {
        this.geocodePlacemark(selectedAddress);
      } else {
        navigator.geolocation.getCurrentPosition(position => {
          const coords = [position.coords.latitude, position.coords.longitude];
          this.setPlacemark(coords);

          this.ymaps
            .geocode(coords)
            .then(res => {
              this.setAddressFromGeoObject(res.geoObjects.get(0));
              this.setMapCenter(coords, 15);

              return true;
            })
            .catch(e => {
              console.error('Yandex Maps Api', e);
              this.searchPlacemark.options.set('visible', false);
            });
        });
      }

      this.mapRef.events.add('click', e => {
        const coords = e.get('coords');
        this.setPlacemark(coords);

        this.ymaps
          .geocode(coords)
          .then(res => {
            this.setAddressFromGeoObject(res.geoObjects.get(0));
            this.setMapCenter(coords);

            return true;
          })
          .catch(error => {
            console.error('Yandex Maps Api', error);
            this.searchPlacemark.options.set('visible', false);
          });
      });
    });
  };

  geocodePlacemark = address => {
    if (this.searchPlacemark) {
      this.searchPlacemark.options.set('visible', false);
    }

    let addressString = address;
    if (typeof address === 'object' && address.value) {
      addressString = address.value;
    }

    if (this.ymaps && this.mapRef) {
      this.ymaps
        .geocode(addressString)
        .then(res => {
          this.address = addressString;
          const coords = res.geoObjects.get(0).geometry.getCoordinates();

          if (coords) {
            this.setPlacemark(coords);
            this.setMapCenter(coords, 15);
          }

          return true;
        })
        .catch(e => {
          console.error('Yandex Maps Api', e);
        });
    }
  };

  render() {
    const { className, center, mapLocale, width, height } = this.props;
    const { currentCenter, isLoaded } = this.state;

    return (
      <div className={cx('Map', className)}>
        {!isLoaded && <Preloader className={cx('Map__preloader')} />}
        {config.legal === 'de' ? (
          <GMaps
            loadingElement={<div />}
            containerElement={<div style={{ width: '100%', height: '100%' }} />}
            mapElement={<div style={{ width: '100%', height: '100%', zIndex: '2' }} />}
            defaultZoom={10}
            defaultCenter={center}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              draggableCursor: 'crosshair',
            }}
            getMapRef={this.getMapRef}
          />
        ) : (
          <YMaps query={{ lang: mapLocale, apikey: config.yandexApiKey }} enterprise>
            <Map
              onLoad={this.handleApiAvaliable}
              width={width}
              height={height}
              instanceRef={this.instanceRef}
              state={{
                center: _isEmpty(currentCenter) ? center : currentCenter,
                zoom: 10,
              }}
            >
              <ZoomControl />
            </Map>
          </YMaps>
        )}
      </div>
    );
  }
}

AddressMap.defaultProps = {
  className: '',
  width: '100%',
  height: '100%',
};

AddressMap.propTypes = {
  className: PropTypes.string,
  mapAddressOnChange: PropTypes.func.isRequired,
  selectedAddress: PropTypes.string.isRequired,
  mapLocale: PropTypes.string.isRequired,
  center: PropTypes.array.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};
