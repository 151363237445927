import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';

//components
import InputField from '../../../InputField';
import Button from '../../../Button';
import AddressesField from '../../../AddressFieldGroup/partials/AddressesField';

//State
import { fetchGeoPosition } from '../../../../state/modules/city/actions';

//icons
import PointerIcon from '../../../../icons/pointer.svg';

//styles
import styles from './ModalFirstView.styl';

const cx = classNames.bind(styles);

const ModalFirstView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const address = useSelector(state => state.city.currentGeoPosition);

  const {
    openCityList, clickInputCity, valueCity, setValueCity, addressInLineValue,
    setAddressInLineValue,
    handleOnAddressInLineSelect,
    isError,
    onCorrectCity,
    textBlockInput,
  } = props;

  /** Запрос на разрешение к геопозиции и отправка координат для опеределения города */

  const findGeolocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const coords = [position.coords.longitude, position.coords.latitude];
      dispatch(fetchGeoPosition(coords));
    });
  };

  /** При изменении адреса закидываем новые данные в кастомный хук */

  useEffect(() => {
    handleOnAddressInLineSelect(address);
  }, [address]);


  return (
    <div className={cx('ModalFirstView')}>
      <div className={cx('ModalFirstView__top')}>
        <InputField inputClassname={cx('ModalFirstView__text-city')}
                    className={cx('ModalFirstView__big-input')}
                    onClick={clickInputCity} value={valueCity}
                    onChange={(event) => setValueCity(event.target.value)}
        />

        <Button
          className={cx('ModalFirstView__change-city-button', { 'ModalFirstView__change-city-button_close': openCityList })}
          onClick={findGeolocation}>
          <PointerIcon />
        </Button>
      </div>

      <AddressesField
        className={cx('ModalFirstView__text-street_not-margin')}
        onAddressSelect={handleOnAddressInLineSelect}
        newest
        input={{
          name: 'address_in_line',
          value: addressInLineValue,
          inputClassname: cx('ModalFirstView__text-street'),
          onChange: value => setAddressInLineValue(value),
          required: true,
          placeholder: intl.formatMessage({ id: 'modal.address.placeholderStreet' }),
          isError,
        }}
        textBlockInput={textBlockInput}
      />

      <div className={cx('ModalFirstView__button-block')}>
        <Button className={cx('ModalFirstView__button', 'gray')} onClick={clickInputCity}>
          {intl.formatMessage({ id: 'modal.address.buttonFalse' })}
        </Button>
        <Button className={cx('ModalFirstView__button')} onClick={() => {
          onCorrectCity();
        }}>
          {intl.formatMessage({ id: 'modal.address.buttonTrue' })}
        </Button>
      </div>
    </div>
  );
};

export default ModalFirstView;
