const getConfig = isNew2 => {
  const mainApiUrl = isNew2 ? 'api.papajohns.de' : 'staging-api.papajohns.de';

  return {
    credentials: isNew2 ? 'include' : 'same-origin',
    // Api Url
    apiUrl: `https://${mainApiUrl}`,
    // WebSocket url
    wssUrl: `wss://${mainApiUrl}`,
    // Юрлицо Рус/Англ
    legal: 'de',
    // GTM id
    GTM: 'GTM-T6GMRTPW',
    // Default Lang
    lang: 'de',
    langList: ['de', 'en'],
    // Телефон
    phone: {
      format: '+.. ...-...-...',
      digitsCount: 12,
      firstNumbers: '+49',
    },
    reCaptchaSiteKey: isNew2
      ? '6Lcpi-UoAAAAAOcDFsc9XTFWkb1D8m2fj6A7goPc'
      : '6Lcpi-UoAAAAAHNZmEVqGqHI6bQHla3Fk7mbrrgn',
    merchantID: '',
  };
};

module.exports = getConfig;
