import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import SignUpForm from '../../Forms/SignUpForm';
import SignInForm from '../../Forms/SignInForm';
import NewSignInForm from '../../Forms/NewSignInForm';
import NewSignUpForm from '../../Forms/NewSignUpForm';
import RecoveryPasswordFirstStepForm from '../../Forms/RecoveryPasswordFirstStepForm';
import RecoveryPasswordSecondStepForm from '../../Forms/RecoveryPasswordSecondStepForm';

// State
import { toggleAuthPopup } from '../../../state/modules/ui/actions';

// Config
import config from '../../../config';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

export default function Forms(props) {
  const { registerForm } = props;
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState('signIn');
  const history = useHistory();

  const onPopupOpen = useCallback(() => {
    history.push({ search: qs.stringify({ registerForm: 'open' }) });
  }, [history]);

  const goToSignIn = () => {
    setCurrentStep('signIn');
  };

  const goToSignUp = () => {
    setCurrentStep('signUp');
  };

  const goToRecoveryPasswordFirstStep = () => {
    setCurrentStep('recoveryPassword/firstStep');
  };

  const goToRecoveryPasswordSecondStep = () => {
    setCurrentStep('recoveryPassword/secondStep');
  };

  const onSuccessCallback = () => {
    dispatch(toggleAuthPopup(false));
    history.push({ search: null });
  };

  const signInForm = config.newRegistration ? (
    <NewSignInForm successCallback={onSuccessCallback} goToSignUp={goToSignUp} />
  ) : (
    <SignInForm
      goToRecoveryPassword={goToRecoveryPasswordFirstStep}
      goToSignUp={goToSignUp}
      successCallback={onSuccessCallback}
    />
  );

  useEffect(() => {
    currentStep === 'signUp' && onPopupOpen();
  }, [currentStep]);

  useEffect(() => {
    if (registerForm) {
      setCurrentStep('signUp')
    }
  }, [registerForm])

  const signUpForm = config.newRegistration ? (
    <NewSignUpForm successCallback={onSuccessCallback} goToSignIn={goToSignIn} />
  ) : (
    <SignUpForm successCallback={onSuccessCallback} />
  );

  return (
    <>
      {(currentStep === 'signIn' && registerForm !== 'open') && signInForm}
      {(currentStep === 'signUp' && (registerForm === 'open')) && signUpForm}
      {currentStep === 'recoveryPassword/firstStep' && registerForm !== 'open' && (
        <RecoveryPasswordFirstStepForm
          onCancel={goToSignIn}
          onNext={goToRecoveryPasswordSecondStep}
        />
      )}
      {currentStep === 'recoveryPassword/secondStep' && registerForm !== 'open' && (
        <RecoveryPasswordSecondStepForm
          goToSignIn={goToSignIn}
          onCancel={goToRecoveryPasswordFirstStep}
        />
      )}
    </>
  );
}
