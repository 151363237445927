import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Style
import styles from './Container.styl';

const cx = classNames.bind(styles);

export default function Container(props) {
  const { className, children, size } = props;

  return <div className={cx('Container', className, `Container_size_${size}`)}>{children}</div>;
}

Container.defaultProps = {
  className: '',
  size: 'm',
};

Container.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
};
